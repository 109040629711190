import { zodResolver } from "@hookform/resolvers/zod";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { AgentMessage } from "@/components/agent-message";
import { ContinueButton } from "@/components/continue-button";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { SectionContent, SectionDescription, SectionFooter, SectionHeader, SectionTitle } from "@/components/section";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Form, FormField } from "@/components/ui/form";
import { SearchInput } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { Field } from "@/forms/borderless";
import { BorderlessCurrency } from "@/forms/borderless-currency";
import {
  MarketingPlanState,
  useAppetiteFilterOptionsQuery,
  useOpportunityDetailsQuery,
  useSelectOpportunityCoveragesMutation,
} from "src/generated/graphql";
import { cn } from "src/utils";

const PROPERTY_FIELD = "Property";

const SelectCoverageFormSchema = z.object({
  selectedLinesOfBusiness: z.string().array().min(1, { message: "You must select at least one coverage" }),
  propertyTIV: z.number().optional(),
});

// TODO: Bring back if we want this to be required
// .superRefine((values, ctx) => {
//   if (values.selectedLinesOfBusiness.includes(PROPERTY_FIELD) && !values.propertyTIV) {
//     ctx.addIssue({
//       message: "Property TIV Required to Proceed",
//       code: z.ZodIssueCode.custom,
//       path: ["propertyTIV"],
//     });
//   }
// });

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export const Coverage = ({ skipNavigation = false, navigateTo }: { skipNavigation?: boolean; navigateTo?: string }) => {
  const { opportunityId } = useParams();
  const { data, loading } = useAppetiteFilterOptionsQuery({});
  const selectCoverageFormMethods = useForm<z.infer<typeof SelectCoverageFormSchema>>({
    resolver: zodResolver(SelectCoverageFormSchema),
    defaultValues: {
      selectedLinesOfBusiness: [],
    },
  });
  const { data: { opportunity } = {}, loading: opportunityLoading } = useOpportunityDetailsQuery({
    variables: {
      id: opportunityId ?? "",
    },
    onCompleted({ opportunity }) {
      selectCoverageFormMethods.reset({
        selectedLinesOfBusiness: opportunity?.selectedLinesOfBusiness ?? [],
      });
    },
  });
  const [selectCoveragesMutation, { loading: submitting }] = useSelectOpportunityCoveragesMutation();
  const navigate = useNavigate();
  const [coverageTerm, setCoverageTerm] = useState("");
  const { toast } = useToast();

  if (!opportunity) {
    return null;
  }

  const sortedVerticals = [...(data?.appetiteFilterOptions?.verticals || [])].sort(
    (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
  );

  const linesOfBusiness = uniqBy(
    sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
    (v) => v.name
  ).filter((l) => l.name.toLowerCase().includes(coverageTerm.toLowerCase()));

  const coreLines = linesOfBusiness.filter((l) => CORE_LINES.has(l.name));
  const otherLines = linesOfBusiness.filter((l) => !CORE_LINES.has(l.name));

  const {
    control,
    formState: { isValid },
  } = selectCoverageFormMethods;

  const { selectedLinesOfBusiness, propertyTIV } = selectCoverageFormMethods.watch();

  const handleSubmit = async () => {
    if (!opportunityId) {
      return null;
    }

    const validate = await selectCoverageFormMethods.trigger();

    if (!validate) {
      return;
    }

    void selectCoveragesMutation({
      variables: {
        input: {
          id: opportunityId,
          selectedLinesOfBusiness,
          propertyTIV,
        },
      },
      onCompleted: () => {
        toast({ title: "Coverage Updated" });
        opportunity;
        if (!skipNavigation) {
          navigate(navigateTo ?? `/insured/${opportunity?.insuredId}/plans/${opportunityId}`);
        }
      },
      refetchQueries: ["OpportunityDetails"],
    });
    return null;
  };

  return (
    <>
      {opportunity.state === MarketingPlanState.WaitingForCoverages && (
        <AgentMessage state={opportunity.state} wrapperClassName="p-6 pb-0" />
      )}
      <Form {...selectCoverageFormMethods}>
        <SectionHeader>
          <SectionTitle>
            <h2>Coverage</h2>
            <SearchInput
              name="search"
              placeholder="Search Coverage"
              value={coverageTerm}
              onChange={(event) => setCoverageTerm(event.currentTarget.value)}
            />
          </SectionTitle>
          {selectCoverageFormMethods.getFieldState("selectedLinesOfBusiness").error && (
            <SectionDescription className="text-destructive">
              {selectCoverageFormMethods.getFieldState("selectedLinesOfBusiness").error?.message}
            </SectionDescription>
          )}
        </SectionHeader>
        <ScrollPaneColumns className="grid-cols-2 pt-0">
          <Card className="overflow-hidden">
            <h4 className="p-6">Core Lines</h4>
            {loading || opportunityLoading ? (
              <SectionContent>
                <Loading />
              </SectionContent>
            ) : coreLines[0] ? (
              coreLines.map(({ name }) => (
                <div key={name} className="border-t first:border-0">
                  <FieldCheckbox label={name} name="selectedLinesOfBusiness" />
                  {name === PROPERTY_FIELD && selectedLinesOfBusiness.includes(PROPERTY_FIELD) && (
                    <BorderlessCurrency
                      className="border-t placeholder-destructive"
                      icon="paid"
                      iconClassName={isValid ? "" : "text-destructive hover:text-destructive"}
                      control={control}
                      name="propertyTIV"
                      required={true}
                      placeholder="Total Insurable Value"
                      inputClassName="!placeholder-destructive"
                    />
                  )}
                </div>
              ))
            ) : (
              <SectionContent className="italic pt-0 text-muted-foreground text-sm">
                No lines found matching <strong>{coverageTerm}</strong>.
              </SectionContent>
            )}
          </Card>
          <Card className="overflow-hidden">
            <h4 className="p-6">Additional Lines</h4>
            {loading ? (
              <SectionContent>
                <Loading />
              </SectionContent>
            ) : otherLines[0] ? (
              otherLines.map(({ name }) => (
                <div key={name}>
                  <Separator />
                  <FieldCheckbox label={name} name="selectedLinesOfBusiness" />
                </div>
              ))
            ) : (
              <SectionContent className="italic pt-0 text-muted-foreground text-sm">
                No lines found matching <strong>{coverageTerm}</strong>.
              </SectionContent>
            )}
          </Card>
        </ScrollPaneColumns>
        <SectionFooter className="bottom-0 sticky">
          <ContinueButton onClick={handleSubmit} disabled={!isValid} submitting={submitting} className="ml-auto" />
        </SectionFooter>
      </Form>
    </>
  );
};

const FieldCheckbox = ({ label, name }: { label: string; name: string }) => (
  <FormField
    name={name}
    render={({ field }) => {
      const checked = field.value?.includes(label);
      return (
        <Field className={cn("gap-6", checked ? "text-foreground" : "text-muted-foreground")}>
          <Checkbox
            checked={checked}
            className={cn(checked ? "border-foreground" : "border-muted-foreground")}
            onCheckedChange={(checked) => {
              return checked
                ? field.onChange([...field.value, label])
                : field.onChange(field.value?.filter((value: string) => value !== label));
            }}
          />
          {label}
        </Field>
      );
    }}
  />
);
