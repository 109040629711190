import { useNodes } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import { useMemo, type FC } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { ButtonGroup } from "@/forms-v2/button-group";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { Form } from "@/forms-v2/form";
import { FormGroup } from "@/forms-v2/form-group";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import {
  CreatePipelineVersionInput,
  FileProcessingPipelineQuery,
  FileProcessorCategory,
  FileProcessorNodeType,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
  useFileProcessorQuery,
} from "src/generated/graphql";

import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  findParentNodeByChildId,
} from "../../file-processing-pipeline.helpers";

export interface EditProcessorFormValues {
  id: string;
  previousId: string;
  type: FileProcessorCategory;
  name: string;
  startPage: string;
  endPage: string;
}

export interface EditProcessorFormProps {
  processorId: string;
}

export const EditProcessorFormContent: FC<EditProcessorFormProps> = () => {
  const { register } = useFormContext();
  const { closeModal } = useModal();

  return (
    <FormGroup className="gap-6">
      <DialogHeader>
        <DialogTitle>Edit processor</DialogTitle>
        <DialogDescription>Update the file processor details.</DialogDescription>
      </DialogHeader>

      <FormGroup>
        <FieldInput name="id" label="File processor ID" placeholder="Enter processor ID" />
        <FieldInput name="type" label="Type" placeholder="Type unavailable" inputProps={{ readOnly: true }} />
        <FieldInput name="name" label="Name" placeholder="Name unavailable" inputProps={{ readOnly: true }} />
        <input type="hidden" {...register("previousId")} />
      </FormGroup>

      <div>
        <h4 className="!text-base">Page range</h4>
        <p className="text-muted-foreground text-sm mt-1.5">
          Enter the page range to use to limit the number of pages processed by this processor with each re-run.
        </p>
      </div>

      <FormGroup className="flex-row">
        <FieldInput name="startPage" label="Start page" type="number" placeholder="Defaults to first page" />
        <FieldInput name="endPage" label="End page" type="number" placeholder="Defaults to last page" />
      </FormGroup>

      <Separator />

      <ButtonGroup className="justify-end">
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit>Save processor</FormSubmit>
      </ButtonGroup>
    </FormGroup>
  );
};

export const EditProcessorForm: FC<EditProcessorFormProps> = (props) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { data: pipelineData, loading: pipelineDataLoading } = useFileProcessingPipelineQuery();
  const { data: fileProcessorData, loading: fileProcessorDataLoading } = useFileProcessorQuery({
    variables: { id: props.processorId },
  });
  const [createPipelineVersion] = useCreateFilePipelineVersionMutation({
    // TODO: We need to figure out the `optimisticResponse` for this mutation.
    // update: (cache, { data }) => {
    //   cache.writeQuery({
    //     query: FileProcessingPipelineDocument,
    //     data: { fileProcessingPipeline: data?.createPipelineVersion },
    //   });
    // },
  });

  const { nodes } = useMemo(
    () => (pipelineData ? convertPipelineDataToNodesAndEdges(pipelineData?.fileProcessingPipeline) : { nodes: [] }),
    [pipelineData]
  );

  const validationSchema = z
    .object({
      id: z
        .string()
        .min(1, { message: "Please enter the processor ID" })
        .refine(
          (value) => {
            const existingNode = nodes.find(
              (node) =>
                node.data.id !== props.processorId &&
                node.type === FileProcessorNodeType.FileProcessor &&
                node.data.id === value
            );

            return !existingNode;
          },
          {
            message: "There is already a processor with that id. Please enter a unique id.",
          }
        ),
      previousId: z.string().min(1, { message: "Previous ID is required" }),
      type: z.string().optional(),
      name: z.string().optional(),
      startPage: z
        .number({ coerce: true })
        .int()
        .refine((value) => Number(value) >= 0, { message: "Must be a positive number" })
        .optional(),
      endPage: z
        .number({ coerce: true })
        .int()
        .refine((value) => Number(value) >= 0, { message: "Must be a positive number" })
        .optional(),
    })
    .superRefine((values, context) => {
      if (values.startPage && values.endPage && values.startPage > values.endPage) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "End page must be greater than or equal to start page",
          path: ["endPage"],
        });
      }
    });

  const defaultValues = {
    id: props.processorId,
    previousId: props.processorId,
    name: fileProcessorData?.fileProcessor?.name || "",
    type: (fileProcessorData?.fileProcessor?.category || "") as FileProcessorCategory,
    startPage: `${fileProcessorData?.fileProcessor?.startPage || ""}`,
    endPage: `${fileProcessorData?.fileProcessor?.endPage || ""}`,
  };

  const handleSubmit = async (values: EditProcessorFormValues) => {
    if (!pipelineData) {
      return;
    }

    const input = getEditProcessorInput(values, pipelineData.fileProcessingPipeline);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline", "FileProcessor"],
    });

    navigate(`/file-processing-pipeline/file-processor/${values.id}`);
    toast({ title: `${values.name} processor updated` });
    closeModal();
  };

  if (!pipelineData && pipelineDataLoading && !fileProcessorData && fileProcessorDataLoading) {
    return <Loading />;
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <EditProcessorFormContent {...props} />
    </Form>
  );
};

export interface UseEditProcessorFormModalOptions {
  processorId?: string;
}

export const useEditProcessorFormModal = ({ processorId }: UseEditProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const processor = findNodeById(processorId, nodes);

  return {
    openEditProcessorForm: async () => {
      if (!processorId || !processor) {
        return;
      }

      await openModal(() => <EditProcessorForm processorId={processorId} />);
    },
  };
};

function getEditProcessorInput(
  values: EditProcessorFormValues,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"]
): CreatePipelineVersionInput | null {
  const pipelineDataCopy = cloneDeep(pipelineData);
  // QUESTION: Can we just use the `useNodes` and `useEdges` hooks here?
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipelineDataCopy);

  const currentNode = findNodeById(values.previousId, nodes);

  if (!currentNode) {
    return null;
  }

  const isInitialProcessor = pipelineDataCopy.pipeline.initial.id === values.previousId;

  if (isInitialProcessor) {
    pipelineDataCopy.pipeline.initial.id = values.id;
    pipelineDataCopy.pipeline.initial.startPage = Number(values.startPage);
    pipelineDataCopy.pipeline.initial.endPage = Number(values.endPage);

    return {
      name: "FileUploadPipeline",
      pipeline: {
        initial: pipelineDataCopy.pipeline.initial,
        transitions: pipelineDataCopy.pipeline.transitions,
      },
    };
  }

  const parentNode = findParentNodeByChildId(currentNode.id, nodes, edges);

  if (!parentNode) {
    return null;
  }

  const existingLabel = pipelineDataCopy.pipeline.transitions.find(
    (transition) => transition.label === parentNode.data.name && transition.sourceNodeName === parentNode.data.category
  );

  const existingProcessor = existingLabel?.destinationNodes.find((node) => node.id === values.previousId);

  if (!existingProcessor) {
    return null;
  }

  existingProcessor.id = values.id;
  existingProcessor.startPage = Number(values.startPage);
  existingProcessor.endPage = Number(values.endPage);

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineDataCopy.pipeline.initial,
      transitions: pipelineDataCopy.pipeline.transitions,
    },
  };
}
