import { createContext } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Card, CardFooter } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { InternalDropzone } from "@/files/internal-dropzone";
import {
  File_Audience,
  MarketingPlanState,
  OpportunityRequirementsQuery,
  useOpportunityRequirementsQuery,
} from "src/generated/graphql";
import { RequirementsActions } from "./actions/requirements-actions";
import { AddRequirements } from "./add-requirements";
import { RequirementsList } from "./requirements-list";

export interface RequirementsContextData {
  opportunity: OpportunityRequirementsQuery["opportunity"];
  refetch: () => void;
}

export const RequirementsContext = createContext<RequirementsContextData>({
  opportunity: null,
  refetch: () => {},
});

export const Requirements = () => {
  const { opportunityId } = useParams();

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityRequirementsQuery({
    variables: { id: opportunityId ?? "" },
    pollInterval: 2000,
  });

  if (
    !opportunity ||
    opportunity.state === MarketingPlanState.WaitingForCoverages ||
    opportunity.state === MarketingPlanState.Complete
  ) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <RequirementsContext.Provider value={{ opportunity, refetch }}>
      <Card>
        <SectionHeader>
          <SectionTitle>
            <h3>Required Information</h3>
            <HasInternalRole>
              <div className="flex gap-2 items-center">
                <AddRequirements opportunityId={opportunity.id} />
                <RequirementsActions opportunity={opportunity} refetch={refetch} />
              </div>
            </HasInternalRole>
          </SectionTitle>
        </SectionHeader>
        <RequirementsList />
        <InternalDropzone audience={File_Audience.External} onDrop={() => refetch()}>
          <CardFooter className="border-t p-3">
            <div className="bg-accent border border-dashed cursor-pointer flex flex-auto gap-6 items-center px-6 py-5 rounded-md text-muted-foreground">
              <Icon icon="cloud_upload" className="text-2xl" />
              <div className="text-sm">Click here, or drag and drop files to start an upload.</div>
            </div>
          </CardFooter>
        </InternalDropzone>
      </Card>
    </RequirementsContext.Provider>
  );
};
