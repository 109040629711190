import { useState } from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router";

import { Dialog } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { File_Audience } from "src/generated/graphql";
import { cn } from "src/utils";
import { uploadFiles } from "src/utils/file";
import { EditFileDialog } from "./edit-file-dialog";

interface Props extends React.PropsWithChildren {
  onDrop?: () => void;
  audience: File_Audience;
  source?: string;
  clickable?: boolean;
  editFileDialog?: boolean;
  className?: string;
}

export const InternalDropzone = ({
  children,
  onDrop,
  audience,
  source = "MANUAL_UPLOAD",
  clickable = true,
  editFileDialog = false,
  className,
}: Props) => {
  const { insuredId } = useParams<"insuredId">();
  const [fileId, setFileId] = useState(undefined);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Dropzone
      noClick={!clickable}
      onDrop={async (files) => {
        if (editFileDialog && files.length > 1) {
          toast({ title: "Please upload one file at a time" });
          return;
        }

        setIsUploading(true);
        const id = await uploadFiles(files, insuredId ?? "", audience, source);
        setFileId(id);
        setIsUploading(false);
        onDrop && onDrop();
      }}
    >
      {({ getRootProps, isDragActive }) => (
        <div className={cn("relative", className)} {...getRootProps()}>
          {children}
          <Dialog onOpenChange={() => setFileId(undefined)} open={fileId ? true : false}>
            {editFileDialog && fileId && <EditFileDialog fileId={fileId} setFileId={setFileId} />}
          </Dialog>
          {isDragActive && (
            <Overlay>
              <Icon icon="cloud_upload" className="text-2xl" /> Drop file to upload
            </Overlay>
          )}
          {isUploading && (
            <Overlay>
              <Spinner />
            </Overlay>
          )}
        </div>
      )}
    </Dropzone>
  );
};

const Overlay = ({ children }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="absolute inset-0 bg-background/90 flex gap-2 items-center justify-center text-muted-foreground">
    {children}
  </div>
);
