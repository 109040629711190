import { NoActions } from "@/components/no-actions";
import { Button } from "@/components/ui/button";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Link } from "react-router-dom";
import { QuoteFragment, QuoteState } from "src/generated/graphql";
import { BindRequestReceived } from "./bind-request-received";
import { Delivered } from "./delivered";
import { NotTaken } from "./not-taken";
import { Processed } from "./processed";

export const QuoteActions = ({ quote }: { quote: QuoteFragment }) => {
  const renderQuoteState = () => {
    switch (quote.state) {
      case QuoteState.Processed:
        return <Processed quote={quote} />;
      case QuoteState.Delivered:
        return <Delivered quote={quote} />;
      case QuoteState.BindRequestReceived:
        return <BindRequestReceived quote={quote} />;
      case QuoteState.NotTaken:
        return <NotTaken quote={quote} />;
      default:
        return <NoActions />;
    }
  };

  const {
    insuredId,
    submission: { opportunityId },
    policy,
  } = quote;

  return (
    <>
      <Separator />
      <CardHeader>
        <CardTitle>Actions</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">{renderQuoteState()}</CardContent>
      <Separator />
      <CardContent className="pt-5 space-y-2">
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`/insured/${insuredId}/plans/${opportunityId}`}>Go to Marketing Plan</Link>
        </Button>
        {policy && (
          <Button variant="outline" size="sm" display="flex" asChild>
            <Link to={`/insured/${insuredId}/policies/${policy.id}`}>Go to Policy</Link>
          </Button>
        )}
      </CardContent>
    </>
  );
};
