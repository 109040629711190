import { isArray, isEmpty } from "lodash";
import { cloneElement, ReactElement, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ClientDataContext } from "@/client-data/client-data-context";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { useDeleteClientDataRowMutation } from "src/generated/graphql";

interface Props {
  children: React.ReactElement;
  description?: React.ReactNode;
  header: React.ReactNode;
  title?: string;
  source?: string;
}

export const Schedule: React.FC<Props> = ({ children, description, header, title, source = "supplemental" }) => {
  const { insuredId } = useContext(ClientDataContext);
  const [numRows, setNumRows] = useState(4);
  const [deleteClientData] = useDeleteClientDataRowMutation();
  const { formState, unregister, getValues } = useFormContext();

  useEffect(() => {
    const childElements = children?.props?.children;
    const defaultValuesKeys = Object.keys(getValues());

    if (childElements && childElements.length > 0) {
      const columns = childElements.map((c: ReactElement) => c.props.name);

      const values = columns.map((column: string) => defaultValuesKeys.filter((k) => k.includes(column)).length);

      const largestValue = Math.max(...values, numRows);
      if (largestValue > numRows) {
        setNumRows(largestValue - 1);
      }
    } else {
      const singleColumn = children?.props?.children?.props?.name;
      const numRecords = defaultValuesKeys.filter((k) => k.includes(singleColumn)).length;

      if (numRecords >= numRows) {
        setNumRows(numRecords);
      }
    }
  }, [formState.defaultValues]);

  const clonedRows = () => [...Array.from({ length: numRows }).keys()].map((n) => clonedRow(n));

  const clonedRow = (key: number) => {
    return children.props.children.length > 0 ? (
      <tr key={key}>
        {children.props.children.map((child: ReactElement) =>
          cloneElement(child, {
            name: `${child.props.name}--${key}`,
            key: `${child.props.name}--${key}`,
            index: key,
          })
        )}
      </tr>
    ) : (
      <tr>
        {cloneElement(children.props.children, {
          key: `${children.props.children.props.name}--${key}`,
          name: `${children.props.children.props.name}--${key}`,
          index: key,
        })}
      </tr>
    );
  };

  const handleDeleteRow = () => {
    if (!insuredId || numRows === 0) {
      return;
    }

    const index = numRows - 1;

    const deleteFormValue = (key: string) => {
      if (!isEmpty(getValues(key))) {
        void deleteClientData({
          variables: {
            input: {
              insuredId,
              key,
              source,
            },
          },
          onCompleted: () => {
            unregister(key);
          },
        });
      }
    };

    // Get the keys from the last row's children
    const row = clonedRow(index);
    const children = row.props.children;
    if (isArray(children)) {
      children.forEach((child) => {
        const key = child.key ?? child.props.name;
        deleteFormValue(key);
      });
    } else {
      const key = children.key ?? children.props.name;
      deleteFormValue(key);
    }

    setNumRows(numRows - 1);
  };

  return (
    <section className="relative">
      <div className="control bg-muted filled flex gap-2 items-center p-0.5 rounded-md shadow-md">
        <Button size="icon-sm" variant="outline" onClick={handleDeleteRow} disabled={numRows === 0}>
          <Icon icon="remove" />
        </Button>
        <div className="text-center text-xs w-4">{numRows}</div>
        <Button size="icon-sm" variant="outline" onClick={() => setNumRows(numRows + 1)}>
          <Icon icon="add_2" />
        </Button>
      </div>
      {title && <h1>{title}</h1>}
      {description && <div style={{ marginBottom: "8pt" }}>{description}</div>}
      <table>
        <thead>{header}</thead>
        <tbody>{clonedRows()}</tbody>
      </table>
    </section>
  );
};
