import { PopoverClose } from "@radix-ui/react-popover";
import { useSearchParams } from "react-router-dom";

import { SelectAgency } from "@/components/select/select-agency";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

export const BankanFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon-sm" className="rounded-full">
          <Icon icon="filter_list" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="grid w-72">
        <SelectAgency />
        {searchParams.get("agencyId") && (
          <div className="flex justify-end mt-4">
            <PopoverClose asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  setSearchParams(
                    {},
                    {
                      replace: true,
                    }
                  )
                }
              >
                Reset
              </Button>
            </PopoverClose>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
