import { useImpersonateUser } from "@cp/auth";

import { HasRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Roles, UserAccountFragment } from "src/generated/graphql";

export const ImpersonateUser = ({ user }: { user: UserAccountFragment }) => {
  const { mutate: impersonateUser } = useImpersonateUser();
  return (
    <HasRole roles={[Roles.Admin]}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon-sm"
            onClick={() => {
              impersonateUser(
                { userIdToImpersonate: user.id },
                { onSuccess: () => window.location.replace(window.location.origin) }
              );
            }}
          >
            <Icon icon="account_circle" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          Impersonate {user.firstName} {user.lastName}
        </TooltipContent>
      </Tooltip>
    </HasRole>
  );
};
