import { useReactFlow, useStore } from "@xyflow/react";

import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";

export const ZoomIn = () => {
  const maxZoomReached = useStore((s) => s.transform[2] >= s.maxZoom);
  const { zoomIn } = useReactFlow();

  const handleZoomInClick = async () => await zoomIn();

  useKeyboardShortcut(["command+=", "ctrl+="], handleZoomInClick, { disabled: maxZoomReached });

  return (
    <ToolbarMenuItem label="Zoom in" icon="add_2" shortcut="⌘+" onClick={handleZoomInClick} disabled={maxZoomReached} />
  );
};
