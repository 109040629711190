import { KnownClientData } from "@qw/qw-common";
import { useNavigate } from "react-router";
import { z } from "zod";

import { toast } from "@/components/ui/use-toast";
import {
  BindHqApplicationType,
  BindHqInsuredType,
  BindHqLineOfBusiness,
  BindHqQuoteBillingType,
  BindHqQuoteBusinessType,
  BindHqQuoteLineItemType,
  BindHqQuoteTaxesCollectedBy,
  useBindQuoteMutation,
  useCreateClientDataMutation,
  useUpdateAgencyMutation,
  useUpdateBindHqAgencyMutation,
  useUpdateInsuredMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

const bindHqPercentageRegex =
  "^(?<sign>[-+])?(?:(?:(?<integral>[0-9]+)?(?<point>.)?(?<fractional>[0-9]+)?(?:[eE](?<exponent>[-+]?[0-9]+))?)|(?:(?<numerator>[0-9]+)/?(?<denominator>[0-9]+)))$";
const BindHqPolicyRequirementsSchema = z
  .object({
    agency: z.object({
      agencyId: z.string().min(1),
      bindHqId: z.string().min(1, { message: "Required" }),
    }),
    insured: z.object({
      id: z.string().min(1),
      bindHqId: z.string().optional(),
      name: z.string().min(1),
      dba: z.string().optional(),
      legalEntityType: z.enum(Object.values(BindHqInsuredType) as [BindHqInsuredType]).optional(),
    }),
    clientData: z.object({
      [KnownClientData.MailingAddress.AddressLine1]: z.string().optional(),
      [KnownClientData.MailingAddress.AddressLine2]: z.string().optional(),
      [KnownClientData.MailingAddress.City]: z.string().optional(),
      [KnownClientData.MailingAddress.State]: z.string().optional(),
      [KnownClientData.MailingAddress.Zip]: z.string().optional(),
    }),
    updateBindHqAgency: z.object({
      billingContactFirstName: z.string().optional(),
      billingContactLastName: z.string().optional(),
      phone: z.string().min(10),
      email: z.string().email(),
      line1: z.string().min(1),
      line2: z.string().optional(),
      city: z.string().min(1),
      state: z.string().min(2).max(2),
      zip: z.string().min(5).max(5),
    }),
    bindHqInput: z.object({
      createApplication: z.object({
        agentBindHqId: z.string().min(1),
        linesOfBusiness: z.array(z.enum(Object.values(BindHqLineOfBusiness) as [BindHqLineOfBusiness])).min(1),
        type: z.enum(Object.values(BindHqApplicationType) as [BindHqApplicationType]),
      }),
      createQuote: z.object({
        marketingCompanyId: z.string().min(1),
        carrierId: z.string().min(1),
        businessType: z.enum(Object.values(BindHqQuoteBusinessType) as [BindHqQuoteBusinessType]),
        billingType: z.enum(Object.values(BindHqQuoteBillingType) as [BindHqQuoteBillingType]),
        taxesCollectedBy: z.enum(Object.values(BindHqQuoteTaxesCollectedBy) as [BindHqQuoteTaxesCollectedBy]),
        taxationHomeState: z.string().min(2).max(2),
        minimumEarnedPremiumPercentage: z
          .string()
          .min(1, { message: "Required" })
          .regex(new RegExp(bindHqPercentageRegex)),
        grossCommissionPercentage: z.string().min(1, { message: "Required" }).regex(new RegExp(bindHqPercentageRegex)),
        agentCommissionPercentage: z.string().min(1, { message: "Required" }).regex(new RegExp(bindHqPercentageRegex)),
        lineItems: z.array(
          z.object({
            type: z.enum(Object.values(BindHqQuoteLineItemType) as [BindHqQuoteLineItemType]),
            amount: z.union([z.string().min(1), z.number()]).transform((v) => v.toString()),
            lineOfBusiness: z.enum(Object.values(BindHqLineOfBusiness) as [BindHqLineOfBusiness]),
            description: z.string().optional(),
          })
        ),
      }),
      createPolicy: z.object({
        policyNumber: z.string().min(1, { message: "Required" }),
        invoiceDueDate: z.string({ required_error: "Required" }).min(1),
        effectiveDate: z.string({ required_error: "Required" }).min(1),
        expirationDate: z.string({ required_error: "Required" }).min(1),
      }),
    }),
  })
  .superRefine((values, context) => {
    if (!values.insured.bindHqId && !values.insured.legalEntityType) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["insured", "legalEntityType"],
      });
    }
  });

export const useBindQuote = (insuredId: string | undefined, quoteId: string | undefined, onCompleted?: () => void) => {
  const navigate = useNavigate();

  const [updateAgency] = useUpdateAgencyMutation({
    onError: (error) =>
      toast({ title: "Error updating Agency", description: parseError(error), variant: "destructive" }),
  });

  const [updateBindHqAgency] = useUpdateBindHqAgencyMutation({
    onError: (error) =>
      toast({ title: "Error updating BindHQ Agency", description: parseError(error), variant: "destructive" }),
  });

  const [updateInsured] = useUpdateInsuredMutation({
    onError: (error) =>
      toast({ title: "Error updating Insured", description: parseError(error), variant: "destructive" }),
  });

  const [createClientData] = useCreateClientDataMutation({
    onError: (error) =>
      toast({ title: "Error updating ClientData", description: parseError(error), variant: "destructive" }),
  });

  const [bindQuote] = useBindQuoteMutation({
    refetchQueries: ["Policies"],
    onError: (error) =>
      toast({ title: "Error creating Policy in BindHQ", description: parseError(error), variant: "destructive" }),
    onCompleted: ({ bindQuote: quote }) => {
      const { policy } = quote;
      onCompleted?.();
      navigate(`/insured/${insuredId}/policies/${policy?.id}`);
    },
  });

  return async (data: z.infer<typeof BindHqPolicyRequirementsSchema>) => {
    const agencyResponse = await updateAgency({
      variables: {
        input: data.agency,
      },
    });
    const bindHqAgencyResponse = await updateBindHqAgency({
      variables: {
        input: {
          id: data.agency.agencyId,
          bindHqId: data.agency.bindHqId,
          billingContactFirstName: data.updateBindHqAgency.billingContactFirstName,
          billingContactLastName: data.updateBindHqAgency.billingContactLastName,
          phone: data.updateBindHqAgency.phone,
          email: data.updateBindHqAgency.email,
          line1: data.updateBindHqAgency.line1,
          line2: data.updateBindHqAgency.line2,
          city: data.updateBindHqAgency.city,
          state: data.updateBindHqAgency.state,
          zip: data.updateBindHqAgency.zip,
        },
      },
    });
    const insuredResponse = await updateInsured({
      variables: {
        input: data.insured,
      },
    });
    let clientDataResponse = undefined;
    if (!data.insured.bindHqId && data.clientData) {
      clientDataResponse = await createClientData({
        variables: {
          input: {
            insuredId: data.insured.id,
            source: "BIND_HQ_POLICY_SUBMISSION", // ClientDataSources.BindHqPolicySubmission enum value
            clientDataInputs: Object.entries(data.clientData)
              .filter(([, value]) => Boolean(value))
              .map(([key, value]) => ({
                key: key,
                value: value!,
                index: null,
              })),
          },
        },
      });
    }

    if (agencyResponse.errors || bindHqAgencyResponse.errors || insuredResponse.errors || clientDataResponse?.errors) {
      return;
    }

    await bindQuote({
      variables: {
        input: {
          quoteId,
          ...data.bindHqInput,
        },
      },
    });
  };
};
