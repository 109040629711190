import { useEdges, useNodes } from "@xyflow/react";

import { useMemo } from "react";

import { findNodeById } from "./file-processing-pipeline.helpers";

export const useHasDownStreamProcessors = (processorId?: string): boolean => {
  const nodes = useNodes();
  const edges = useEdges();

  return useMemo(() => {
    if (!processorId) {
      return false;
    }

    return !!findNodeById(processorId, nodes)?.data?.hasGrandchildren;
  }, [processorId, nodes, edges]);
};
