import { zodResolver } from "@hookform/resolvers/zod";
import { HTMLAttributes, PropsWithChildren } from "react";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
} from "react-hook-form";
import { z } from "zod";

type AsyncDefaultValues<TFieldValues> = (payload?: unknown) => Promise<TFieldValues>;

type DefaultSchema = z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>;

export interface FormProps<TFieldValues extends FieldValues, TSchema extends DefaultSchema>
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit" | "onError"> {
  defaultValues?: AsyncDefaultValues<TFieldValues> | DefaultValues<TFieldValues>;
  validationSchema?: TSchema;
  onSubmit?: SubmitHandler<TFieldValues>;
  onError?: SubmitErrorHandler<TFieldValues>;
  mode?: Mode;
  useFormProps?: UseFormProps<TFieldValues>;
}

export function Form<TFieldValues extends FieldValues, TSchema extends DefaultSchema>({
  mode = "onTouched",
  defaultValues = {} as any,
  validationSchema,
  onSubmit,
  onError,
  useFormProps,
  children,
  ...props
}: PropsWithChildren<FormProps<TFieldValues, TSchema>>) {
  const methods = useForm<TFieldValues>({
    mode,
    defaultValues,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
    ...useFormProps,
  });

  const handleSubmit: SubmitHandler<TFieldValues> = async (values, event) => {
    return await onSubmit?.(values, event);
  };

  const handleError: SubmitErrorHandler<TFieldValues> = async (errors, event) => {
    return await onError?.(errors, event);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit, handleError)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}
