import { Outlet } from "react-router";

import { cn } from "src/utils";
import { AppNav } from "./app-nav";
import { ModalProvider } from "./modal-provider";
import { SidebarProvider } from "./sidebar";

export const Page = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => (
  <ModalProvider>
    <div className="gradient" />
    <div className="radial" />
    <AppNav />
    <div
      className={cn(
        "flex min-h-dvh w-dvw relative z-0",
        "landscape:p-2 landscape:pl-16",
        "portrait:pb-[--nav-height]",
        className
      )}
      id="Page"
    >
      <SidebarProvider>{children}</SidebarProvider>
    </div>
  </ModalProvider>
);

export const Centered = () => (
  <>
    <div className="gradient" />
    <div className="radial" />
    <div className="flex flex-col items-center justify-center min-h-dvh p-4 relative">
      <Outlet />
    </div>
  </>
);
