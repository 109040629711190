import { partition } from "lodash";

import { COLUMN_STATE_METADATA } from "@/column-metadata";
import { Loading } from "@/components/ui/loading";
import { BankanPolicyColumn, useBankanPolicyColumnQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { ExpiringPoliciesCard } from "./cards/expiring-policies-card";
import { PoliciesCard } from "./cards/policies-card";

export const PolicyColumn = ({
  column,
  agencyId,
  brokerId,
  pinnedInsureds,
}: {
  column: BankanPolicyColumn;
  agencyId?: string | null;
  brokerId?: string | null;
  pinnedInsureds: string[];
}) => {
  const {
    data: { bankanPolicyColumn: { groupedPolicies } } = { bankanPolicyColumn: { groupedPolicies: [] } },
    loading,
  } = useBankanPolicyColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [pinned, unpinned] = partition(groupedPolicies, ({ insured }) => pinnedInsureds.includes(insured.id));
  const cardClassName = COLUMN_STATE_METADATA[column]["cardClassName"];

  return (
    <BankanColumn id={column} count={groupedPolicies.length}>
      {loading ? (
        <Loading />
      ) : (
        [...pinned, ...unpinned].map((group) =>
          column === BankanPolicyColumn.ExpiringSoon ? (
            <ExpiringPoliciesCard key={group.insuredId} group={group} />
          ) : (
            <PoliciesCard key={group.insuredId} group={group} cardClassName={cardClassName} />
          )
        )
      )}
    </BankanColumn>
  );
};
