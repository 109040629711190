import { partition } from "lodash";

import { COLUMN_STATE_METADATA } from "@/column-metadata";
import { Loading } from "@/components/ui/loading";
import { MarketingPlanBankanColumn, useBankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { MarketingPlansCard } from "./cards/marketing-plans-card";

export const MarketingPlanColumn = ({
  column,
  agencyId,
  brokerId,
  pinnedInsureds,
}: {
  column: MarketingPlanBankanColumn;
  agencyId?: string | null;
  brokerId?: string | null;
  pinnedInsureds: string[];
}) => {
  const {
    data: { bankanMarketingPlanColumn: { groupedMarketingPlans } } = {
      bankanMarketingPlanColumn: { groupedMarketingPlans: [] },
    },
    loading,
  } = useBankanMarketingPlanColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        column: column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [pinned, unpinned] = partition(groupedMarketingPlans, ({ insured }) => pinnedInsureds.includes(insured.id));
  const cardClassName = COLUMN_STATE_METADATA[column]["cardClassName"];

  return (
    <BankanColumn id={column} count={groupedMarketingPlans.length}>
      {loading ? (
        <Loading />
      ) : (
        [...pinned, ...unpinned].map((group) => (
          <MarketingPlansCard key={group.insuredId} group={group} cardClassName={cardClassName} />
        ))
      )}
    </BankanColumn>
  );
};
