import { Grid, GridCell, GridRowHeader } from "@/components/grid";
import { HasInternalRole } from "@/components/has-role";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Card } from "@/components/ui/card";
import { useMyAccount } from "@/hooks/use-my-account";
import { useOpportunity } from "@/hooks/use-opportunity";
import { MarketingPlanActions } from "@/marketing-plans/actions/marketing-plan-actions";
import { SubmissionState } from "src/generated/graphql";
import { Submission } from "./submission";

const SUBMISSION_ORDER = [
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.Pending,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
];

export const Submissions = () => {
  const { data: user } = useMyAccount();
  const { opportunity, refetch } = useOpportunity();

  if (!user || !opportunity) {
    return null;
  }

  const visibleSubmissions = opportunity.submissions.filter(
    (submission) => submission.state !== SubmissionState.Pending || user.internal
  );

  return (
    <Card>
      <SectionHeader>
        <SectionTitle>
          <h3>Submissions</h3>
          <HasInternalRole>
            <MarketingPlanActions marketingPlan={opportunity} />
          </HasInternalRole>
        </SectionTitle>
      </SectionHeader>
      {visibleSubmissions.length === 0 ? (
        <div className="italic p-6 pt-0 text-muted-foreground text-sm">Submissions pending.</div>
      ) : (
        <Grid className="grid-cols-[1.5rem_1fr_1.5fr_1fr_1.5fr]">
          <GridRowHeader>
            <GridCell />
            <GridCell>Carrier</GridCell>
            <GridCell>Product</GridCell>
            <GridCell>Updated</GridCell>
            <GridCell />
          </GridRowHeader>
          {visibleSubmissions
            .sort((a, b) => {
              const aIndex = SUBMISSION_ORDER.indexOf(a.state);
              const bIndex = SUBMISSION_ORDER.indexOf(b.state);
              return aIndex - bIndex;
            })
            .map((submission) => (
              <Submission key={submission.id} submission={submission} refetch={refetch} />
            ))}
        </Grid>
      )}
    </Card>
  );
};
