import { Toolbar, ToolbarMenu, ToolbarSeparator } from "@/components/ui/toolbar";

// import { History } from "./tools/history";
import { Issues } from "./tools/issues";
import { Search } from "./tools/search";
import { Share } from "./tools/share";
// import { ToggleSidePanel } from "./tools/toggle-side-panel";

export const Settings = () => {
  return (
    <Toolbar position="top-right">
      <ToolbarMenu>
        <Search />
        <ToolbarSeparator />
        <Share />
      </ToolbarMenu>

      <ToolbarMenu>
        <Issues />
      </ToolbarMenu>
    </Toolbar>
  );
};
