import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Icon, IconString } from "@/components/ui/icon";
import { cn } from "src/utils";

export const MoreMenu = ({
  children,
  className,
  icon = "more_horiz",
}: {
  children: React.ReactNode;
  className?: string;
  icon?: IconString;
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button
        variant="ghost"
        size="icon-sm"
        className={cn("!bg-transparent rounded-full hover:text-foreground", className)}
      >
        <Icon icon={icon} className="font-bold" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">{children}</DropdownMenuContent>
  </DropdownMenu>
);
