import { NodeProps } from "@xyflow/react";
import { startCase, toLower } from "lodash";
import { FoldHorizontal, UnfoldHorizontal } from "lucide-react";
import { FC } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { FileProcessorCategory } from "src/generated/graphql";

import { useEditProcessorFormModal } from "../../../components/forms/edit-processor-form";
import { useRemoveProcessorFormModal } from "../../../components/forms/remove-processor-form";
import { useRerunProcessorFormModal } from "../../../components/forms/rerun-processor-form";
import { processorCategoryToIconMap } from "../../../file-processing-pipeline.constants";
import { useFileProcessingPipelineState } from "../../../file-processing-pipeline.provider";
import { NodeBase } from "./node-base";
import { NodeAction, NodeActionType } from "./node-toolbar";

export interface FileProcessorNodeProps extends NodeProps {
  data: {
    id: string;
    name: string;
    category: FileProcessorCategory;
    isRoot: boolean;
    hasChildren: boolean;
    hasGrandchildren: boolean;
  };
}

export const FileProcessorNode: FC<FileProcessorNodeProps> = ({ data, ...props }) => {
  const navigate = useNavigate();
  const { toggleExpandedNode, expandedNodes } = useFileProcessingPipelineState();
  const { openRerunProcessorForm } = useRerunProcessorFormModal({ processorId: data.id });
  const { openEditProcessorForm } = useEditProcessorFormModal({ processorId: data.id });
  const { openRemoveProcessorForm } = useRemoveProcessorFormModal({ processorId: data.id });

  const detailUrlBase = "/file-processing-pipeline/file-processor";
  const detailUrl = `${detailUrlBase}/${encodeURIComponent(data.id)}`;
  const match = useMatch(`${detailUrlBase}/:fileProcessorId`);

  const icon = processorCategoryToIconMap[data.category];
  const label = startCase(toLower(data?.name.replaceAll("_", " ")));

  const isExpanded = expandedNodes.includes(props.id);

  const actions: NodeAction[] = [
    {
      id: "viewDetails",
      icon: "visibility",
      label: "View details",
      onClick: () => navigate(detailUrl),
      isDefault: true,
      isHidden: true,
    },
    {
      id: "rerunProcessor",
      icon: "replay",
      label: "Rerun processor",
      onClick: openRerunProcessorForm,
    },
    {
      id: "editProcessor",
      icon: "edit",
      label: "Edit processor",
      onClick: openEditProcessorForm,
    },
    {
      id: "removeProcessor",
      icon: "delete",
      label: "Remove processor",
      onClick: openRemoveProcessorForm,
      isHidden: data.isRoot || data.hasGrandchildren,
    },
    {
      id: "expandCollapse",
      icon: isExpanded ? <FoldHorizontal className="h-4 w-4" /> : <UnfoldHorizontal className="h-4 w-4" />,
      label: `${isExpanded ? "Collapse" : "Expand"}`,
      onClick: () => toggleExpandedNode(props.id),
      isHidden: data.isRoot || !data.hasChildren,
    },
    { type: NodeActionType.Separator },
    {
      id: "close",
      icon: "close",
      label: "Close",
      onClick: () => navigate(`/file-processing-pipeline`),
    },
  ];

  return (
    <NodeBase
      label={label}
      icon={icon}
      actions={actions}
      isActive={data.id === decodeURIComponent(match?.params.fileProcessorId || "")}
      isRoot={data.isRoot}
      hasChildren={data.hasChildren}
      {...props}
    />
  );
};
