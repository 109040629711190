import { SidePanel, SidePanelHeader, SidePanelSection } from "@/components/side-panel";

export const IssuesSidePanel = () => {
  return (
    <SidePanel>
      <SidePanelHeader
        icon="data_alert"
        label="Manage pipeline"
        title="Issues"
        onCloseNavigatePath="/file-processing-pipeline"
      />

      <SidePanelSection>Issues list will go here...</SidePanelSection>
    </SidePanel>
  );
};
