import { uniqBy } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { SectionContent, SectionHeader } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { FormField } from "@/components/ui/form";
import { Icon } from "@/components/ui/icon";
import { SearchInput } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Field } from "@/forms/borderless";
import { Input } from "@/forms/default";
import {
  useAddProductToVerticalMarketingPlanTemplateMutation,
  useAppetiteFilterOptionsQuery,
  useAppetiteProductsQuery,
  useCreateVerticalMarketingPlanTemplateMutation,
  useVerticalQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { TemplateHierarchy } from "./template-hierarchy";
import { TemplateRequirements } from "./template-requirements";
import { TemplateRules } from "./template-rules";

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

type Tabs = "rank" | "rules" | "gathering";

export const VerticalTemplates = () => {
  const { verticalId } = useParams();
  const { openForm } = useModal();
  const [tab, setTab] = useState<Tabs>("rank");
  const { data, loading, refetch } = useVerticalQuery({ variables: { id: verticalId ?? "" }, skip: !verticalId });

  const [createVerticalMarketingPlanTemplateTrigger] = useCreateVerticalMarketingPlanTemplateMutation({
    onCompleted() {
      void refetch();
    },
  });
  const [addProductToVerticalMarketingPlanTemplateTrigger] = useAddProductToVerticalMarketingPlanTemplateMutation({
    onCompleted() {
      void refetch();
    },
  });

  if (loading) {
    return <Loading className="m-6" />;
  }

  if (!data) {
    return <h2>Error loading Marketing Plan Templates</h2>;
  }

  const vertical = data.vertical;

  const createVerticalMarketingPlan = async () => {
    const fd = await openForm(
      z.object({
        lobs: z.array(z.string()),
      }),
      <SelectLinesOfBusiness />,
      { defaultValues: { lobs: [] } }
    );

    if (!fd) {
      return;
    }

    await createVerticalMarketingPlanTemplateTrigger({
      variables: { input: { verticalId: vertical.id, lobs: fd.lobs.sort() } },
    });
  };

  const addProductToVerticalMarketingPlan = async (verticalMarketingPlanTemplateId: string) => {
    const fd = await openForm(
      z.object({
        products: z.array(z.string()),
      }),
      <SelectProducts />,
      { defaultValues: { products: [] } }
    );

    if (!fd) {
      return;
    }

    await addProductToVerticalMarketingPlanTemplateTrigger({
      variables: { input: { verticalMarketingPlanTemplateId, appetiteProductIds: fd.products } },
    });
  };

  return (
    <>
      <SectionHeader className="bg-accent border-b flex items-center justify-between sticky top-24 z-10">
        <Tabs value={tab} onValueChange={(v) => setTab(v as Tabs)}>
          <TabsList>
            <TabsTrigger value="rank">Hierarchy</TabsTrigger>
            <TabsTrigger value="rules">Rules</TabsTrigger>
            <TabsTrigger value="gathering">Information Gathering</TabsTrigger>
          </TabsList>
        </Tabs>
        <Button variant="outline" size="sm" onClick={createVerticalMarketingPlan}>
          Add Template
        </Button>
      </SectionHeader>
      {[...vertical.marketingPlanTemplates]
        .sort((a, b) => a.lobs[0]?.charCodeAt(0) - b.lobs[0]?.charCodeAt(0))
        .sort((a, b) => b.lobs.length - a.lobs.length)
        .map((template) => (
          <SectionContent key={template.id} className="border-b last:border-none">
            <Card className="overflow-hidden">
              <CardHeader className="flex-row items-center justify-between">
                <div className="flex gap">
                  {template.lobs.map((lob) => (
                    <Badge key={lob}>{lob}</Badge>
                  ))}
                </div>
                <Button
                  variant="outline"
                  size="xs"
                  className={cn(template.products.length === 0 && "!border-current !text-destructive")}
                  onClick={() => addProductToVerticalMarketingPlan(template.id)}
                >
                  Add Products
                </Button>
              </CardHeader>
              {template.products.length > 0 && (
                <Tabs value={tab}>
                  <TabsContent value="rank" asChild>
                    <TemplateHierarchy template={template} refetch={refetch} />
                  </TabsContent>
                  <TabsContent value="rules" asChild>
                    <TemplateRules template={template} refetch={refetch} />
                  </TabsContent>
                  <TabsContent value="gathering" asChild>
                    <TemplateRequirements refetch={refetch} products={template.products} />
                  </TabsContent>
                </Tabs>
              )}
            </Card>
          </SectionContent>
        ))}
    </>
  );
};

function SelectLinesOfBusiness() {
  const [term, setTerm] = useState("");
  const { data } = useAppetiteFilterOptionsQuery({});

  const sortedVerticals = [...(data?.appetiteFilterOptions?.verticals || [])].sort(
    (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
  );

  const linesOfBusiness = uniqBy(
    sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
    (v) => v.name
  ).filter((l) => l.name.toLowerCase().includes(term.toLowerCase()));

  const coreLines = linesOfBusiness.filter((l) => CORE_LINES.has(l.name));
  const otherLines = linesOfBusiness.filter((l) => !CORE_LINES.has(l.name));

  return (
    <div className="grid grid-flow-col gap-2 grid-rows-12 mt-4 h-full max-h-96">
      <div className="flex-auto space-y-4 row-span-11 overflow-scroll">
        <Card className="flex gap-5 p-6 pl-5">
          <Icon icon="verified" className="filled text-primary text-2xl" />
          <h2>Select Lines of Business</h2>
        </Card>
        <Input
          name="search"
          type="text"
          placeholder="Search for lobs"
          className="px-4"
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        />
        <Card className="bg-muted overflow-hidden space-y-px">
          <CardHeader className="bg-background">
            <CardTitle>Core Lines</CardTitle>
          </CardHeader>
          {coreLines.map(({ name }) => (
            <FieldCheckbox key={name} value={name} name="lobs" />
          ))}
        </Card>
        <Card className="bg-muted overflow-hidden space-y-px">
          <CardHeader className="bg-background">
            <CardTitle>Other</CardTitle>
          </CardHeader>
          {otherLines.map(({ name }) => (
            <FieldCheckbox key={name} value={name} name="lobs" />
          ))}
        </Card>
      </div>
      <Button>Create</Button>
    </div>
  );
}

function SelectProducts() {
  const { data } = useAppetiteProductsQuery();
  const [term, setTerm] = useState("");

  return (
    <div className="mt-8">
      <div className="max-h-96 overflow-scroll">
        <SearchInput
          className="min-w-full w-full"
          name="search"
          placeholder="Search Products"
          value={term}
          onChange={(event) => setTerm(event.currentTarget.value)}
        />
        {[...(data?.appetiteProducts ?? [])]
          .sort((a, b) => a.carrierName.localeCompare(b.carrierName))
          .filter((p) => {
            return (
              p.carrierName.toLowerCase().includes(term.toLowerCase()) ||
              p?.carrierProductName?.toLowerCase().includes(term.toLowerCase())
            );
          })
          .map((product) => (
            <FieldCheckbox
              key={product.id}
              label={`${product.carrierName} ${product.carrierProductName}`}
              value={product.id}
              name="products"
            />
          ))}
      </div>
      <div className="flex justify-center">
        <Button>Add Products</Button>
      </div>
    </div>
  );
}

const FieldCheckbox = ({ label, value, name }: { label?: string; value: string; name: string }) => (
  <FormField
    name={name}
    render={({ field }) => {
      const checked = field.value?.includes(value);
      return (
        <Field className={cn("gap-6", checked ? "text-foreground" : "text-muted-foreground")}>
          <Checkbox
            checked={checked}
            className={cn(checked ? "border-foreground" : "border-muted-foreground")}
            onCheckedChange={(checked) => {
              return checked
                ? field.onChange([...field.value, value])
                : field.onChange(field.value?.filter((fieldValue: string) => fieldValue !== value));
            }}
          />
          {label ?? value}
        </Field>
      );
    }}
  />
);
