import { Link, useParams } from "react-router-dom";

import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { NavigateAgency } from "@/components/navigate/navigate-agency";
import { Sidebar, SidebarContent, SidebarHeader, SidebarSection } from "@/components/sidebar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { useMyAccount } from "@/hooks/use-my-account";
import { PinnedInsureds } from "@/insureds/pinned-insureds";
import { InsuredsRequests } from "./insureds-requests";

export const InsuredsSidebar = ({ id }: { id: string }) => {
  const { data: user } = useMyAccount();
  const { agencyId } = useParams();

  if (!user) {
    return null;
  }

  return (
    <Sidebar side="right">
      <SidebarHeader>
        <HasInternalRole elseShow={user.agency.name}>
          <NavigateAgency path="/insureds" buttonProps={{ variant: "outline", size: "sm" }} />
          <Button variant="ghost" size="icon-sm" asChild>
            <Link to="/broker">
              <Icon icon="close" />
            </Link>
          </Button>
        </HasInternalRole>
      </SidebarHeader>
      <SidebarContent>
        <InsuredsRequests id={id} />
        <PinnedInsureds />
        <HasInternalRole>
          {agencyId && (
            <SidebarSection>
              <AgencyAffiliations id={agencyId} />
            </SidebarSection>
          )}
        </HasInternalRole>
      </SidebarContent>
    </Sidebar>
  );
};
