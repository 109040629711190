import { Toolbar, ToolbarMenu, ToolbarSeparator } from "@/components/ui/toolbar";
import { CollapseAll } from "./tools/collapse-all";
import { FitView } from "./tools/fit-view";
import { ZoomIn } from "./tools/zoom-in";
import { ZoomOut } from "./tools/zoom-out";

export const Controls = () => {
  return (
    <Toolbar position="left-bottom">
      <ToolbarMenu>
        <CollapseAll />
        <ToolbarSeparator />
        <ZoomIn />
        <ZoomOut />
        <FitView />
      </ToolbarMenu>
    </Toolbar>
  );
};
