import { useState } from "react";

import { MoreMenu } from "@/components/more-menu";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { SubmissionFragment } from "src/generated/graphql";
import { CarrierContacts } from "./carrier-contacts/carrier-contacts";

export const SubmissionMenu = ({
  children,
  submission,
}: {
  children?: React.ReactNode;
  submission: SubmissionFragment;
}) => {
  const [contactsOpen, setContactsOpen] = useState(false);

  const portalLink = submission.appetiteProduct.portalLink ?? undefined;

  return (
    <>
      <MoreMenu>
        <DropdownMenuItem onClick={() => setContactsOpen(true)}>
          <Icon icon="account_circle" />
          Carrier Contacts
        </DropdownMenuItem>
        {portalLink && (
          <a href={portalLink} target="_blank" rel="noopener noreferrer">
            <DropdownMenuItem>
              <Icon icon="language" />
              Carrier Portal
            </DropdownMenuItem>
          </a>
        )}
        {children && (
          <>
            <DropdownMenuSeparator />
            {children}
          </>
        )}
      </MoreMenu>
      <CarrierContacts submission={submission} open={contactsOpen} setOpen={setContactsOpen} />
    </>
  );
};
