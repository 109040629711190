import { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { OpportunityEmailTemplate, useCreateEmailTemplateDraftMutation } from "src/generated/graphql";
import { parseError } from "src/utils";
import { MarketingEffortSubmission } from "./submission-actions";
import { SubmissionTemplateModal } from "./submission-template-modal";

export const EmailDraft = ({
  submission,
  template = OpportunityEmailTemplate.SubmitToCarrier,
}: {
  submission: MarketingEffortSubmission;
  template?: OpportunityEmailTemplate;
}) => {
  const { insuredId, opportunityId } = useParams();
  const { toast } = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const [createEmailTemplateDraft] = useCreateEmailTemplateDraftMutation();

  const handleCreateEmailDraft = async (fileIds: string[]) => {
    toast({ title: "Creating Email Draft", description: "Please wait..." });
    await createEmailTemplateDraft({
      variables: {
        input: {
          submissionId: submission.id,
          template,
          fileIds: fileIds,
        },
      },
      onCompleted: (data) => {
        if (data?.createEmailTemplateDraft) {
          window.open(data.createEmailTemplateDraft, "_blank");
          setModalOpen(false);
        }
      },
      onError: (error) => {
        toast({ title: "Error", description: parseError(error), variant: "destructive" });
      },
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (template === OpportunityEmailTemplate.SubmitToCarrier) {
      e.preventDefault();
      setModalOpen(true);
    } else {
      void handleCreateEmailDraft([]);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="ghost" size="icon-sm" onClick={(e) => handleClick(e)}>
            <Icon icon="mail" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Draft Email</TooltipContent>
      </Tooltip>
      <SubmissionTemplateModal
        open={modalOpen}
        onClose={handleModalClose}
        clientId={insuredId ?? ""}
        opportunityId={opportunityId ?? ""}
        handleCreateEmailDraft={handleCreateEmailDraft}
      />
    </>
  );
};
