import { useAtom } from "jotai";
import { createContext, useContext, useState } from "react";

import { rowsPerPageAtom } from "@/atoms";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

const rowsPerPageOptions = ["50", "100", "250", "500"];

interface PaginationState {
  numPages: number;
  setNumPages: React.Dispatch<React.SetStateAction<number>>;
  numRecords: number;
  setNumRecords: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: string;
  setRowsPerPage: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: PaginationState = {
  numPages: 1,
  setNumPages: () => null,
  numRecords: 1,
  setNumRecords: () => null,
  page: 1,
  setPage: () => null,
  rowsPerPage: "50",
  setRowsPerPage: () => null,
};

const PaginationContext = createContext<PaginationState>(initialState);

export function PaginationProvider({ children }: { children: React.ReactNode }) {
  const [numPages, setNumPages] = useState(1);
  const [numRecords, setNumRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);

  const value = {
    numPages,
    setNumPages,
    numRecords,
    setNumRecords,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  };

  return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>;
}

export const usePagination = () => {
  const context = useContext(PaginationContext);

  if (context === undefined) {
    throw new Error("usePagination must be used within a PaginationProvider");
  }

  return context;
};

export const Pagination = ({ label }: { label: string }) => {
  const { numPages, numRecords, page, setPage, rowsPerPage, setRowsPerPage } = usePagination();
  return (
    <Bar as="footer" className="gap-1 justify-center mt-auto text-muted-foreground text-xs">
      <div className="absolute flex items-center inset-0 right-auto ml-6 my-auto">
        {numRecords} {label}
      </div>
      {numPages > 1 && (
        <>
          <Button variant="ghost" size="icon-sm" disabled={page === 1} onClick={() => setPage(1)}>
            <Icon icon="first_page" />
          </Button>
          <Button
            variant="ghost"
            size="icon-sm"
            disabled={page === 1}
            onClick={() => setPage((page) => (page > 1 ? page - 1 : 1))}
          >
            <Icon icon="chevron_left" />
          </Button>
          <span className="flex-none tabular-nums text-center w-24">
            Page {page} of {numPages}
          </span>
          <Button
            variant="ghost"
            size="icon-sm"
            disabled={page === numPages}
            onClick={() => setPage((page) => (page < numPages ? page + 1 : numPages))}
          >
            <Icon icon="chevron_right" />
          </Button>
          <Button variant="ghost" size="icon-sm" disabled={page === numPages} onClick={() => setPage(numPages)}>
            <Icon icon="last_page" />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="xs"
                className="absolute flex items-center inset-0 left-auto mr-2.5 my-auto text-xs"
              >
                {rowsPerPage}
                <Icon icon="keyboard_arrow_down" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>Rows per page</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup
                value={rowsPerPage}
                onValueChange={(value) => {
                  setRowsPerPage(value);
                  setPage(1);
                }}
              >
                {rowsPerPageOptions.map((option) => (
                  <DropdownMenuRadioItem key={option} value={option}>
                    {option}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      )}
    </Bar>
  );
};
