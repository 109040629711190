import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { HasRole } from "@/components/has-role";
import { ScrollPane } from "@/components/scroll-pane";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { Roles } from "src/generated/graphql";

const settingsPages = [
  { to: "/admin/enablement", label: "Enablement" },
  { to: "/admin/appetite", label: "Appetite" },
  { to: "/admin/appetite-notes", label: "Appetite Notes" },
  { to: "/admin/gmail-filters", label: "Gmail Filters" },
  { to: "/admin/tags", label: "Tags" },
];

export const Admin = () => {
  useDocumentTitle("Admin: QuoteWell");
  return (
    <ScrollPane id="AdminRoot">
      <Bar>
        <BarLinks>
          <HasRole roles={[Roles.Admin]}>
            {settingsPages.map(({ label, to }) => (
              <BarLink key={to} to={to}>
                {label}
              </BarLink>
            ))}
          </HasRole>
        </BarLinks>
      </Bar>
      <Outlet />
    </ScrollPane>
  );
};
