import { FC, PropsWithChildren } from "react";
import { useFileProcessingPipelineState } from "./file-processing-pipeline.provider";

export const FileProcessingPipelineSidePanel: FC<PropsWithChildren> = ({ children }) => {
  const { isSidePanelVisible } = useFileProcessingPipelineState();

  if (!isSidePanelVisible) {
    return null;
  }

  return <>{children}</>;
};
