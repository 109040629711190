import { Maps } from "@cp/toolkit";
import { uniq } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { ContinueButton } from "@/components/continue-button";
import { HasInternalRole, hasInternalRoleOrInternalAdmin } from "@/components/has-role";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { Reform } from "@/forms/reform";
import { useMyAccount } from "@/hooks/use-my-account";
import { useOpportunity } from "@/hooks/use-opportunity";
import {
  ScoredAppetiteProductFragment,
  useAgencyAppointmentsQuery,
  useSearchAppetiteForOpportunityQuery,
  useUpdateOpportunityMutation,
} from "src/generated/graphql";
import { MarketsList } from "./markets-list";

const FormSchema = z.object({
  productIds: z.string().array(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;

export const SelectMarkets: React.FC<{ initialSelect?: boolean }> = () => {
  const { insuredId } = useParams();
  const { opportunity } = useOpportunity();
  const { data: user } = useMyAccount();
  const { toast } = useToast();

  const { data: { agencyAppointments = [] } = {} } = useAgencyAppointmentsQuery({
    variables: {
      agencyId: opportunity?.insured?.agent?.agencyId ?? "",
    },
    skip: !opportunity,
  });
  const directAppointments = agencyAppointments.map((a) => a.carrierSlug);

  const { loading, data } = useSearchAppetiteForOpportunityQuery({
    variables: {
      id: opportunity?.id ?? "",
    },
    skip: !opportunity,
  });

  const navigate = useNavigate();

  const [updateOpportunity, { loading: isSubmittingOpportunity }] = useUpdateOpportunityMutation({
    onCompleted: (res) => {
      toast({ title: "Markets Updated" });
      navigate(`/insured/${insuredId}/plans/${res.updateOpportunity.id}`);
    },
    onError: () => {
      toast({ title: "An Error Occurred" });
    },
  });

  if (!opportunity) {
    return null;
  }

  const { id, insured, selectedLinesOfBusiness, appetiteProducts } = opportunity;
  const requestedLines = uniq(selectedLinesOfBusiness);
  let content: React.ReactNode;

  if (data) {
    const { scoredProducts } = data.appetiteSearchForOpportunity;
    const highScoringProducts = getTopThreeProductsPerCoreLine(scoredProducts, selectedLinesOfBusiness);
    let defaultProductIds = appetiteProducts.map((o) => o.id);

    const scoredProductIdSet = new Set(scoredProducts.map((p) => p.product.id));
    const absentSelectedProductIds = defaultProductIds.filter((id) => !scoredProductIdSet.has(id));

    if (user && !hasInternalRoleOrInternalAdmin(user) && appetiteProducts.length === 0) {
      defaultProductIds = highScoringProducts.map((o) => o.id);
    }
    content = (
      <Reform
        // This key forces the form to rerender if this length changes (which can occur when core lines are updated)
        key={absentSelectedProductIds.length}
        method="POST"
        id="selections-form"
        schema={FormSchema}
        defaultValues={{
          productIds: defaultProductIds,
        }}
        onSubmit={async (_, { productIds }) => {
          await updateOpportunity({
            variables: {
              // Don't override products that aren't currently visible
              input: { appetiteProductIds: [...productIds, ...absentSelectedProductIds], id },
            },
          });
        }}
        className="space-y-3"
      >
        <MarketsList
          scoredProducts={scoredProducts}
          requestedLines={requestedLines}
          directAppointments={directAppointments}
        />
      </Reform>
    );
  } else if (loading) {
    content = <Loading className="p-4" />;
  } else {
    content = (
      <Card>
        <CardHeader>
          <CardTitle>Oops!</CardTitle>
          <CardDescription>
            We can&apos;t display markets &ndash; we&apos;re missing some important information.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <p>
            {selectedLinesOfBusiness[0] ? (
              <span>Lines: {selectedLinesOfBusiness.join(", ")}</span>
            ) : (
              <span className="text-destructive">No lines selected.</span>
            )}
          </p>
          <p>
            {insured.businessClasses[0] ? (
              <span>Business Class: {insured.businessClasses[0].description}</span>
            ) : (
              <span className="text-destructive">No business class.</span>
            )}
          </p>
          <p>
            {insured.primaryState ? (
              <span>Primary State: {insured.primaryState}</span>
            ) : (
              <span className="text-destructive">No primary state selected.</span>
            )}
          </p>
          <p>
            {insured.description ? (
              <span>Business Description: {insured.description}</span>
            ) : (
              <span className="text-destructive">No business description.</span>
            )}
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <HasInternalRole>
      <div className="p-6 space-y-3">
        {content}
        <footer className="bottom-6 flex items-center sticky">
          <ContinueButton
            submitting={isSubmittingOpportunity}
            disabled={!data}
            form="selections-form"
            className="ml-auto pointer-events-auto shadow-xl"
          />
        </footer>
      </div>
    </HasInternalRole>
  );
};

const getTopThreeProductsPerCoreLine = (
  scoredProducts: ScoredAppetiteProductFragment[],
  selectedLinesOfBusiness: string[]
) => {
  const productsGroupedByCoreLine = Maps.multiMap(scoredProducts, (product) =>
    product.product.coreLines.find((line) => selectedLinesOfBusiness.includes(line))
  );

  const selectedProducts = Maps.mapValues(productsGroupedByCoreLine, (products) => {
    const filteredProducts = products.filter((p) => p.score > 0.8);
    const bestProductPerCarrier = Maps.keyBy(filteredProducts, (p) => p.product.carrierName);
    return [...bestProductPerCarrier.values()];
  });

  const topProductPerCoreLine = Maps.mapValues(selectedProducts, (products) =>
    products.sort((a, b) => b.score - a.score).slice(0, 1)
  );

  return [...topProductPerCoreLine.values()]
    .flat()
    .map((p) => p.product)
    .slice(0, selectedLinesOfBusiness.length);
};
