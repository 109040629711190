import { useReactFlow } from "@xyflow/react";

import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";

export const FitView = () => {
  const { fitView } = useReactFlow();

  const handleFitViewClick = async () => await fitView({ duration: 1000 });

  useKeyboardShortcut("ctrl+f", handleFitViewClick);

  return <ToolbarMenuItem label="Fit view" icon="crop_free" shortcut="⌃F" onClick={handleFitViewClick} />;
};
