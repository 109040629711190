import { IconString } from "@/components/ui/icon";
import { Viewport } from "@xyflow/react";
import { FileProcessorCategory } from "src/generated/graphql";

export const initialViewport: Viewport = {
  x: 400,
  y: 410,
  zoom: 0.75,
};

export const processorCategoryToIconMap: Record<FileProcessorCategory, IconString> = {
  [FileProcessorCategory.Splitter]: "arrow_split",
  [FileProcessorCategory.Classifier]: "folder_data",
  [FileProcessorCategory.Extractor]: "database",
  [FileProcessorCategory.PromptExtractor]: "database",
};
