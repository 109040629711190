import { Fragment } from "react";

import { Separator } from "@/components/ui/separator";
import { BankanPolicyColumnQuery } from "src/generated/graphql";
import { BankanCard, BankanCardHeader, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";

type Group = BankanPolicyColumnQuery["bankanPolicyColumn"]["groupedPolicies"][number];

export const PoliciesCard = ({ group, cardClassName }: { group: Group; cardClassName: string }) => {
  const { insuredId, insured, policies } = group;
  return (
    <BankanCard className={cardClassName}>
      <BankanCardHeader insured={insured} />
      {policies.map((policy) => {
        const { id, appetiteProduct, policyNumber, lastTransitionAt } = policy;
        return (
          <Fragment key={id}>
            <Separator />
            <BankanLink to={`/insured/${insuredId}/policies/${id}`}>
              <BankanLinkText>
                <strong>{appetiteProduct.carrierName}: </strong>
                {appetiteProduct.carrierProductName}
              </BankanLinkText>
              <BankanLinkFooter>
                <div>#{policyNumber}</div>
                <DaysSince date={lastTransitionAt} />
              </BankanLinkFooter>
            </BankanLink>
          </Fragment>
        );
      })}
    </BankanCard>
  );
};
