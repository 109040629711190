import { Node, Viewport } from "@xyflow/react";
import isNil from "lodash/isNil";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import { useLocalStorage } from "usehooks-ts";

import { initialViewport } from "./file-processing-pipeline.constants";

export interface FileProcessingPipelineSettings {
  expandedNodes: string[];
  // collapsedNodes: string[];
}

export interface FileProcessingPipelineContextValue extends FileProcessingPipelineSettings {
  isInitialized: boolean;
  setIsInitialized: Dispatch<SetStateAction<boolean>>;
  isTransitioning: boolean;
  setIsTransitioning: Dispatch<SetStateAction<boolean>>;
  defaultViewport: Viewport;
  setDefaultViewport: Dispatch<SetStateAction<Viewport>>;
  selectedNodes: Node[];
  setSelectedNodes: Dispatch<SetStateAction<Node[]>>;
  setExpandedNodes: Dispatch<SetStateAction<string[]>>;
  setExpandedNode: (nodeId: string) => void;
  toggleExpandedNode: (nodeId: string) => void;
  isSidePanelVisible: boolean;
  setIsSidePanelVisible: Dispatch<SetStateAction<boolean>>;
  toggleSidePanelVisibility: () => void;
  // setCollapsedNodes: Dispatch<SetStateAction<string[]>>;
  // toggleCollapsedNode: (nodeId: string) => void;
}

export const FileProcessingPipelineContext = createContext({} as FileProcessingPipelineContextValue);

export const FileProcessingPipelineProvider: FC<PropsWithChildren> = ({ children }) => {
  // const { fitView } = useReactFlow();
  // const viewport = useViewport();
  const navigate = useNavigate();
  const location = useLocation();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);
  const [defaultViewport, setDefaultViewport] = useLocalStorage<Viewport>(
    "fileProcessingPipelineDefaultViewport",
    initialViewport
  );
  const [expandedNodes, setExpandedNodes] = useLocalStorage<string[]>("fileProcessingPipelineExpandedNodes", []);
  const [isSidePanelVisible, setIsSidePanelVisible] = useLocalStorage<boolean>(
    "fileProcessingPipelineIsSidePanelVisible",
    true
  );

  // Initialize relevant view parameters from the search parameters on mount.
  const setInitialViewFromSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchViewport = searchParams.get("viewport");
    const searchExpandedNodes = searchParams.get("expandedNodes");
    const searchViewportArray = searchViewport?.split(",");

    // Set the initial viewport
    if (searchViewportArray) {
      setDefaultViewport({
        x: Number(searchViewportArray[0]),
        y: Number(searchViewportArray[1]),
        zoom: Number(searchViewportArray[2]),
      });
    }

    // Set the initial expanded nodes
    if (!isNil(searchExpandedNodes)) {
      setExpandedNodes(searchExpandedNodes?.split(","));
    }

    // Clear share parameters from URL to avoid issues on refresh.
    if (searchViewport || searchExpandedNodes) {
      searchParams.delete("viewport");
      searchParams.delete("expandedNodes");

      const searchString = searchParams.toString();

      navigate(`${location.pathname}${searchString ? `?${searchString}` : ""}`, { replace: true });
    }
  };

  useEffect(() => {
    setInitialViewFromSearchParams();
  }, []);

  // const { data: pipelineData } = useFileProcessingPipelineQuery();

  // const { nodes, edges } = useMemo(() => {
  //   return pipelineData?.fileProcessingPipeline
  //     ? convertPipelineDataToNodesAndEdges(pipelineData?.fileProcessingPipeline)
  //     : { nodes: [], edges: [] };
  // }, [pipelineData]);

  // const [collapsedNodes, setCollapsedNodes] = useLocalStorage<string[]>("fileProcessingPipelineCollapsedNodes", []);

  // NOTE: We're not using collapsed nodes at the moment.
  // const toggleCollapsedNode = (nodeId: string) => {
  //   setCollapsedNodes((nodes) => (nodes.includes(nodeId) ? nodes.filter((id) => id !== nodeId) : [...nodes, nodeId]));
  // };

  const toggleExpandedNode = useCallback(
    (nodeId: string) => {
      setExpandedNodes((nodes) => (nodes.includes(nodeId) ? nodes.filter((id) => id !== nodeId) : [...nodes, nodeId]));

      // TODO: Planning for a UX enhancement which will focus the expanded node(s)
      // The issue is, we need to know where the nodes will be before expanding because
      // the nodes will be repositioned via the animation upon expanding/collapsing.
      // setTimeout(async () => {
      //   const isExpanded = expandedNodes.includes(nodeId);
      //   const childNodes = findChildNodesByParentId(nodeId, nodes, edges);

      //   await fitView({
      //     nodes: isExpanded ? [{ id: nodeId }] : childNodes.map((node) => ({ id: node.id })),
      //     minZoom: viewport.zoom,
      //     maxZoom: viewport.zoom,
      //     duration: 500,
      //   });
      // }, 50);
    },
    [expandedNodes]
  );

  const toggleSidePanelVisibility = useCallback(() => setIsSidePanelVisible((isVisible) => !isVisible), []);

  const setExpandedNode = useCallback((nodeId: string) => {
    setExpandedNodes((nodes) => (nodes.includes(nodeId) ? nodes : [...nodes, nodeId]));
  }, []);

  const value = useMemo(
    () => ({
      isInitialized,
      setIsInitialized,
      isTransitioning,
      setIsTransitioning,
      defaultViewport,
      setDefaultViewport,
      selectedNodes,
      setSelectedNodes,
      expandedNodes,
      setExpandedNodes,
      setExpandedNode,
      toggleExpandedNode,
      isSidePanelVisible,
      setIsSidePanelVisible,
      toggleSidePanelVisibility,
      // collapsedNodes,
      // setCollapsedNodes,
      // toggleCollapsedNode,
    }),
    [
      isInitialized,
      setIsInitialized,
      isTransitioning,
      setIsTransitioning,
      defaultViewport,
      setDefaultViewport,
      selectedNodes,
      setSelectedNodes,
      expandedNodes,
      setExpandedNodes,
      setExpandedNode,
      toggleExpandedNode,
      isSidePanelVisible,
      setIsSidePanelVisible,
      toggleSidePanelVisibility,
      // collapsedNodes,
      // setCollapsedNodes,
      // toggleCollapsedNode,
    ]
  );

  return <FileProcessingPipelineContext.Provider value={value}>{children}</FileProcessingPipelineContext.Provider>;
};

export const useFileProcessingPipelineState = () => {
  const context = useContext(FileProcessingPipelineContext);

  if (!context) {
    throw new Error("useFileProcessingPipelineState must be used within a FileProcessingPipelineProvider");
  }

  return context;
};
