import { useReactFlow, useStore } from "@xyflow/react";

import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";

export const ZoomOut = () => {
  const minZoomReached = useStore((s) => s.transform[2] <= s.minZoom);
  const { zoomOut } = useReactFlow();

  const handleZoomOutClick = async () => await zoomOut();

  useKeyboardShortcut(["command+-", "ctrl+-"], handleZoomOutClick, { disabled: minZoomReached });

  return (
    <ToolbarMenuItem
      label="Zoom out"
      icon="remove"
      shortcut="⌘-"
      onClick={handleZoomOutClick}
      disabled={minZoomReached}
    />
  );
};
