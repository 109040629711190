import { authDataAtom, useLogout } from "@cp/auth";
import { useAtom } from "jotai";
import { PropsWithChildren } from "react";
import { NavLink as Link, NavLinkProps } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import { useMyAccount } from "@/hooks/use-my-account";
import { Roles } from "src/generated/graphql";
import { cn } from "src/utils";
import { HasExternalRole, HasInternalRole, HasRole } from "./has-role";
import { Search } from "./search";
import { Theme, useTheme } from "./theme-provider";
import { Avatar } from "./ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Icon, type IconString } from "./ui/icon";
import { QuoteWellLogo } from "./ui/quotewell-logo";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export const AppNav = () => {
  const [authData] = useAtom(authDataAtom);
  const { mutate: logout } = useLogout();
  const { data: user, impersonated } = useMyAccount();
  const { theme, setTheme } = useTheme();

  if (!user) {
    return null;
  }

  const bankanRoles = new Set([Roles.Broker, Roles.Dj]);
  const bankanId = user.roles.some((element) => bankanRoles.has(element));

  return (
    <nav
      className={cn(
        "bottom-0 fixed flex flex-none justify-center left-0 overflow-auto z-30",
        "landscape:flex-col landscape:h-dvh landscape:w-16",
        "portrait:bg-background portrait:h-nav portrait:w-full portrait:shadow-xl"
      )}
      id="Sidebar"
    >
      <div className="aspect-square flex items-center justify-center">
        <QuoteWellLogo className={cn("h-7 md:h-9", theme === "dark" && "invert")} />
      </div>
      <div
        className={cn(
          "flex flex-auto items-center gap-0.5 md:gap-2",
          "landscape:flex-col landscape:justify-start",
          "portrait:justify-center"
        )}
      >
        <Search />
        <NavLink to="/insured/new" icon="add_circle" label="New Insured" className="filled" />
        <HasInternalRole>
          <NavLink to="/broker" icon="home" label="Home" />
          <NavLink to={bankanId ? `/bankan/${user.id}` : "/bankan"} icon="view_kanban" label="Bankan" />
          <NavLink to="/launch" icon="rocket_launch" label="Launch" />
          <NavLink to="/admin" icon="token" label="Admin" />
          <NavLink to="/verticals" icon="layers" label="Verticals" />
          <HasRole roles={[Roles.Admin]}>
            <NavLink to="/file-processing-pipeline" icon="folder_data" label="File Processing Pipeline" />
          </HasRole>
          <NavLink to="/supplementals" icon="description" label="Supplementals" />
        </HasInternalRole>
        <HasExternalRole>
          <NavLink to="/insureds" icon="home" label="Home" />
          <NavLink
            to="https://quotewell.notion.site/Help-Center-eedceb626b79430386d913e33f9e8428"
            icon="help"
            label="Help Center"
            target="_blank"
          />
          <NavLink
            to="mailto:support@quotewell.com?subject=QuoteWell+Help"
            icon="mail"
            label="Email QuoteWell Technical Support"
            target="_blank"
          />
        </HasExternalRole>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="aspect-square cursor-pointer flex items-center justify-center" id={user.id}>
            <Avatar user={user} impersonated={impersonated} className="max-md:text-xs" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48 space-y-1">
          <DropdownMenuLabel>
            <Icon icon="display_settings" />
            Appearance
          </DropdownMenuLabel>
          <DropdownMenuRadioGroup value={theme} onValueChange={(v) => setTheme(v as Theme)}>
            <DropdownMenuRadioItem value="light">Light Mode</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="dark">Dark Mode</DropdownMenuRadioItem>
            <HasInternalRole>
              <DropdownMenuRadioItem value="beef">Barbie Mode</DropdownMenuRadioItem>
            </HasInternalRole>
            <DropdownMenuRadioItem value="system">System Setting</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              if (authData) {
                logout(
                  { refreshToken: authData.token.refreshToken ?? authData.token.accessToken },
                  { onSuccess: () => window.location.replace("/") }
                );
              }
            }}
          >
            <Icon icon="logout" />
            Sign Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
};

const NavLink: React.FC<
  PropsWithChildren<{ to: string; label: string; icon: IconString; unread?: number; className?: string } & NavLinkProps>
> = ({ to, icon, label, unread = 0, className, ...props }) => {
  const portrait = useMediaQuery("(orientation: portrait)");
  const { theme } = useTheme();

  return (
    <Tooltip delayDuration={167}>
      <TooltipTrigger>
        <Link
          className={({ isActive, isPending }: { isActive: boolean; isPending: boolean }) =>
            cn(
              buttonClassName,
              "group",
              {
                "text-primary filled": isActive,
                "text-success": isActive && theme === "beef",
                "text-muted-foreground hover:text-foreground": !isActive,
                "opacity-50": isPending,
              },
              className
            )
          }
          to={to}
          aria-label={label}
          {...props}
        >
          <Icon icon={icon} className={"group-aria-[current=page]:text-primary"} />
          {unread > 0 && (
            <div className="absolute bg-destructive h-1.5 items-center justify-center right-2.5 ring-2 ring-background rounded-full top-2.5 translate-x-1/3 w-1.5" />
          )}
        </Link>
      </TooltipTrigger>
      <TooltipContent
        side={portrait ? "top" : "right"}
        className={cn(
          "bg-foreground text-background flex font-semibold items-center h-8 pointer-events-none px-3 rounded-md shadow-none text-xs",
          "portrait:-mb-2"
        )}
      >
        {label}
      </TooltipContent>
    </Tooltip>
  );
};

export const buttonClassName = [
  "aspect-square cursor-pointer flex h-10 tems-center justify-center p-0 relative rounded text-lg md:text-xl transition-colors transition-opacity",
  "h-8 md:h-10",
];
