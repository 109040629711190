import { UseKeyPressOptions } from "@xyflow/react/dist/esm/hooks/useKeyPress";
import { isInputDOMNode } from "@xyflow/system";
import { useEffect } from "react";
import useKeyboardJs from "react-use/lib/useKeyboardJs";

export const useKeyboardShortcut = (
  key: string | string[],
  callback: () => void,
  options?: UseKeyPressOptions & { disabled?: boolean }
) => {
  const { disabled = false } = options || {};

  const [isPressed, keyboardEvent] = useKeyboardJs(key);

  useEffect(() => {
    const handleKeyDown = () => {
      keyboardEvent?.preventDefault();

      if (disabled) {
        return;
      }

      if (!keyboardEvent || isInputDOMNode(keyboardEvent)) {
        return;
      }

      // NOTE: We also call the callback if the keyboard event is a keydown event, because for
      // some reason isPressed is always false when the key combination includes the command key.
      if (!isPressed && keyboardEvent.type !== "keydown") {
        return;
      }

      callback();
    };

    handleKeyDown();
  }, [isPressed, keyboardEvent]);
};
