import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Input as FormInput } from "@/forms/default";
import { SUBMISSION_STATE_METADATA } from "@/metadata";
import {
  SubmissionFragment,
  SubmissionStateMarketBlockedActions,
  SubmissionStatePendingActions,
  SubmissionStateReferredActions,
  SubmissionStateRuledOutActions,
  SubmissionStateSubmittedActions,
  useTransitionSubmissionMutation,
} from "src/generated/graphql";

type Actions =
  | SubmissionStateMarketBlockedActions
  | SubmissionStatePendingActions
  | SubmissionStateReferredActions
  | SubmissionStateRuledOutActions
  | SubmissionStateSubmittedActions;

export const useSubmissionActions = () => {
  const [transition] = useTransitionSubmissionMutation();
  const { openConfirmation, openForm } = useModal();

  const handleConfirmation = async (
    submission: SubmissionFragment,
    action: Actions,
    includeDetails: boolean = false
  ) => {
    const isConfirmed = await (includeDetails
      ? openForm(
          z.object({ details: z.string() }),
          <>
            <h3>Confirm</h3>
            <p>
              {submission.appetiteProduct.carrierName} - {submission.appetiteProduct.carrierProductName}
            </p>
            <div className="my-2">
              <FormInput name="details" placeholder="Reason" />
            </div>
            <div className=" mt-2">
              <Button size="sm">Rule Out</Button>
            </div>
          </>
        )
      : openConfirmation({
          title: `Confirm ${SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[action] ?? action}`,
          description: `${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`,
        }));

    if (!isConfirmed) {
      return;
    }

    await handleTransition(submission, action, typeof isConfirmed === "object" ? isConfirmed.details : undefined);
  };

  const handleTransition = async (submission: SubmissionFragment, action: Actions, details?: string) => {
    await transition({
      variables: {
        input: {
          submissionId: submission.id,
          action,
          details,
        },
      },
      refetchQueries: ["OpportunityDetails"],
    });
  };

  return {
    handleConfirmation,
    handleTransition,
  };
};
