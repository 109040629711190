import { Dates, UUID } from "@cp/toolkit";
import { zodResolver } from "@hookform/resolvers/zod";
import { KnownClientData } from "@qw/qw-common";
import { add } from "date-fns";
import { intersection, sortBy, uniq } from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { z } from "zod";

import { Grid, GridRow, GridRowHeader } from "@/components/grid";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Button } from "@/components/ui/button";
import { FormField, FormItem } from "@/components/ui/form";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Loading, Spinner } from "@/components/ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Currency } from "@/forms/currency";
import { Input } from "@/forms/default";
import { useInsured } from "@/hooks/use-insured";
import {
  BindHqApplicationType,
  BindHqInsuredType,
  BindHqLineOfBusiness,
  BindHqQuoteBillingType,
  BindHqQuoteBusinessType,
  BindHqQuoteLineItemType,
  BindHqQuoteTaxesCollectedBy,
  MarketTypes,
  useBindHqAgenciesQuery,
  useBindHqAgentsQuery,
  useBindHqCarriersQuery,
  useBindHqInsuredsQuery,
  useBindHqMarketingCompaniesQuery,
  useClientDataQuery,
  useQuoteKitchenSinkQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { QuoteInfo } from "../quote-info";
import { BindHqLegalEntityMap, BindHqLineOfBusinessDisplayName, QwLobToBindHqLob } from "./bind-maps";
import { BindHqPolicyRequirementsSchema } from "./bind-schema";
import { BindHQLinesOfBusiness } from "./bindhq-lines-of-business";
import { DatePickerWithError } from "./date-picker-with-error";
import { SelectEnum } from "./select-enum";
import { SelectUSState } from "./select-us-state";
import { useBindQuote } from "./use-bind-quote";

const formId = "bindHqPolicyForm";

export const Bind = () => {
  const { insuredId } = useParams<"insuredId">();
  const { quoteId } = useParams<"quoteId">();
  const { insured, loading: insuredLoading } = useInsured();

  const { data: { quote } = {}, loading: quoteLoading } = useQuoteKitchenSinkQuery({
    variables: { id: quoteId ?? "" },
    skip: !quoteId,
  });

  const { data: { clientData } = {} } = useClientDataQuery({
    variables: { input: { insuredId: insuredId as UUID } },
    skip: !insuredId,
  });

  const { data: { bindHqAgencies = [] } = {}, loading: bindHqAgenciesLoading } = useBindHqAgenciesQuery();
  // Store selected BindHQ agency ID in state so we can use it to fetch BindHQ agents for that agency only
  const [selectedBindHqAgencyId, setSelectedBindHqAgencyId] = useState<string | undefined>(
    insured?.agency?.bindHqId ?? undefined
  );

  const bhqAgency = bindHqAgencies.find((a) => a.id === selectedBindHqAgencyId);

  const { data: { bindHqAgents = [] } = {}, loading: bindHqAgentsLoading } = useBindHqAgentsQuery({
    variables: {
      input: { bindHqAgencyId: selectedBindHqAgencyId },
    },
  });

  const { data: { bindHqInsureds = [] } = {}, loading: bindHqInsuredsLoading } = useBindHqInsuredsQuery({
    variables: {},
  });
  const { data: { bindHqMarketingCompanies = [] } = {}, loading: bindHqMarketingCompaniesLoading } =
    useBindHqMarketingCompaniesQuery();
  const { data: { bindHqCarriers = [] } = {}, loading: bindHqCarriersLoading } = useBindHqCarriersQuery();

  const submitUpdates = useBindQuote(insuredId, quoteId);
  const [submitting, setSubmitting] = useState(false);
  const formMethods = useForm<z.infer<typeof BindHqPolicyRequirementsSchema>>({
    resolver: zodResolver(BindHqPolicyRequirementsSchema),
  });

  const submission = quote?.submission;
  const opportunity = submission?.opportunity;
  const appetiteProduct = submission?.appetiteProduct;
  const { agent, agency } = insured ?? {};
  // Find the lines of business that are both in the submission and the appetite product
  const bindHqLinesOnSubmission = intersection(
    submission?.appetiteProduct?.coreLines,
    opportunity?.selectedLinesOfBusiness
  )
    .map((lob) => QwLobToBindHqLob[lob]) // Convert the QW lines of business to BindHQ lines of business
    .filter((v): v is BindHqLineOfBusiness => v !== undefined); // Remove undefined values
  const selectedBindHqLines = formMethods.watch("bindHqInput.createApplication.linesOfBusiness") ?? [];
  const bindHqInsuredOptions = [{ id: undefined, name: "Create New Insured" }, ...bindHqInsureds];
  const createNewInsuredSelected = formMethods.watch("insured.bindHqId") === undefined;

  useEffect(() => {
    if (opportunity && insured && submission && quote && clientData && !submitting) {
      // Triggers refetch of BindHQ agents for this agency
      agency?.bindHqId && setSelectedBindHqAgencyId(agency?.bindHqId);

      formMethods.reset({
        agency: {
          agencyId: agency?.id,
          bindHqId: agency?.bindHqId ?? undefined,
        },
        insured: {
          id: insured?.id,
          bindHqId: insured?.bindHqId ?? undefined,
          name: insured?.name,
          dba: insured?.dba ?? undefined,
          legalEntityType:
            insured?.legalEntityType && Object.keys(BindHqLegalEntityMap).includes(insured.legalEntityType)
              ? BindHqLegalEntityMap[insured.legalEntityType]
              : undefined,
        },
        clientData: {
          [KnownClientData.MailingAddress.AddressLine1]: clientData.find(
            (d) => d.key === KnownClientData.MailingAddress.AddressLine1
          )?.value,
          [KnownClientData.MailingAddress.AddressLine2]: clientData.find(
            (d) => d.key === KnownClientData.MailingAddress.AddressLine2
          )?.value,
          [KnownClientData.MailingAddress.City]: clientData.find((d) => d.key === KnownClientData.MailingAddress.City)
            ?.value,
          [KnownClientData.MailingAddress.State]: clientData.find((d) => d.key === KnownClientData.MailingAddress.State)
            ?.value,
          [KnownClientData.MailingAddress.Zip]: clientData.find((d) => d.key === KnownClientData.MailingAddress.Zip)
            ?.value,
        },
        updateBindHqAgency: {
          billingContactFirstName: undefined,
          billingContactLastName: undefined,
          phone: bhqAgency?.phone ?? undefined,
          email: bhqAgency?.email ?? undefined,
          line1: bhqAgency?.line1 ?? undefined,
          line2: bhqAgency?.line2 ?? undefined,
          city: bhqAgency?.city ?? undefined,
          state: bhqAgency?.state ?? undefined,
          zip: bhqAgency?.zip ?? undefined,
        },
        bindHqInput: {
          createApplication: {
            agentBindHqId: agent?.bindHqId ?? undefined,
            linesOfBusiness: bindHqLinesOnSubmission,
            type: opportunity.renewalOf ? BindHqApplicationType.Renewal : BindHqApplicationType.New,
          },
          createQuote: {
            marketingCompanyId: appetiteProduct?.bindHqMarketingCompanyId ?? undefined,
            carrierId: appetiteProduct?.bindHqCarrierIds.length === 1 ? appetiteProduct.bindHqCarrierIds[0] : undefined,
            businessType:
              submission.appetiteProduct.marketType === MarketTypes.BindingAuthority
                ? BindHqQuoteBusinessType.Binding
                : BindHqQuoteBusinessType.Brokerage,
            taxationHomeState: insured?.primaryState ?? undefined,
            minimumEarnedPremiumPercentage: quote.mep?.toString(),
            agentCommissionPercentage: quote.commission?.toString(),
            lineItems: [
              {
                type: BindHqQuoteLineItemType.Premium,
                amount: quote?.premium?.toString(),
                lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
              },
              {
                type: BindHqQuoteLineItemType.MgaFee,
                amount: quote.brokerFee?.toString(),
                lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                description:
                  appetiteProduct?.marketType === MarketTypes.BindingAuthority ? "Policy Fee" : "Wholesale Broker Fee",
              },
              ...(quote.carrierFee
                ? [
                    {
                      type: BindHqQuoteLineItemType.CarrierFee,
                      amount: quote.carrierFee?.toString(),
                      lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                    },
                  ]
                : []),
              ...(quote.inspectionFee
                ? [
                    {
                      type: BindHqQuoteLineItemType.InspectionFee,
                      amount: quote.inspectionFee?.toString(),
                      lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                    },
                  ]
                : []),
            ],
          },
          createPolicy: {
            effectiveDate: quote.effectiveDate,
            expirationDate:
              quote.expirationDate ?? quote.effectiveDate
                ? add(new Date(quote.effectiveDate), { years: 1 }).toISOString()
                : undefined,
            // try to use quote effective date, if not available, use opportunity desired effective date
            invoiceDueDate: quote.effectiveDate
              ? Dates.incrementDate({ date: quote.effectiveDate, addDays: 10 }).toISOString()
              : opportunity.desiredEffectiveDate
              ? Dates.incrementDate({ date: opportunity.desiredEffectiveDate, addDays: 10 }).toISOString()
              : undefined,
          },
        },
      });
    }
  }, [opportunity, insured, clientData]);

  useEffect(() => {
    formMethods.setValue("updateBindHqAgency.phone", bhqAgency?.phone ?? "");
    formMethods.setValue("updateBindHqAgency.email", bhqAgency?.email ?? "");
    formMethods.setValue("updateBindHqAgency.line1", bhqAgency?.line1 ?? "");
    formMethods.setValue("updateBindHqAgency.line2", bhqAgency?.line2 ?? "");
    formMethods.setValue("updateBindHqAgency.city", bhqAgency?.city ?? "");
    formMethods.setValue("updateBindHqAgency.state", bhqAgency?.state ?? "");
    formMethods.setValue("updateBindHqAgency.zip", bhqAgency?.zip ?? "");
  }, [bhqAgency]);

  // Trigger validation on each field as it changes to update field error messages in real time
  useEffect(() => {
    const subscription = formMethods.watch((_value, { name }) => {
      if (name) {
        void formMethods.trigger([name]);
      }
    });

    return () => subscription.unsubscribe(); // Cleanup the subscription on unmount.
  }, [formMethods.watch, formMethods.trigger]);

  if (quoteLoading || insuredLoading) {
    return <Loading />;
  }

  if (!opportunity || !submission || !quote || !agency || !agent || !insured) {
    return <>Something&apos;s not right! Please notify EPD.</>;
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      // Schema parse the values to trigger zod transforms
      const values = BindHqPolicyRequirementsSchema.safeParse(formMethods.getValues());
      if (values.success) {
        setSubmitting(true);
        void submitUpdates(values.data).then(() => setSubmitting(false));
      } else {
        console.log(values.error);
      }
    } else {
      console.log(formMethods.formState.errors);
    }
  };

  let requireAscendFields = false;

  if (
    selectedBindHqAgencyId &&
    (!bhqAgency?.phone || !bhqAgency?.email || !bhqAgency?.city || !bhqAgency?.state || !bhqAgency?.zip)
  ) {
    requireAscendFields = true;
  }

  return (
    <>
      {/* <DevTool control={formMethods.control} /> */}
      <QuoteInfo quote={quote} />
      <FormProvider {...formMethods}>
        <form id={formId} onSubmit={(e) => onSubmit(e)} className="border-t py-6 space-y-6">
          <Grid className="grid-cols-3 gap-y-2">
            <Cell>
              <Label>BindHQ Agency*</Label>
              <Error error={formMethods.formState.errors.agency?.bindHqId} />
              <Autocomplete
                placeholder={loadingPlaceholder(bindHqAgenciesLoading, "Select an Agency from BindHQ (Required)")}
                options={bindHqAgencies}
                selected={bindHqAgencies.find((a) => a.id === formMethods.watch("agency.bindHqId"))}
                onSelect={async (bindHqAgency) => {
                  formMethods.setValue("agency.bindHqId", bindHqAgency.id);
                  setSelectedBindHqAgencyId(bindHqAgency.id);
                }}
                toValue={(a) => `${a.name} ${a.id}`}
                toLabel={(a) => a.name}
                buttonProps={{ variant: "outline", display: "flex" }}
              />
            </Cell>
            <Cell>
              <Label>BindHQ Agent*</Label>
              <Error error={formMethods.formState.errors.bindHqInput?.createApplication?.agentBindHqId} />
              <Autocomplete
                placeholder={loadingPlaceholder(bindHqAgentsLoading, "Select an Agent from BindHQ (Required)")}
                options={bindHqAgents}
                selected={bindHqAgents.find(
                  (a) => a.id === formMethods.watch("bindHqInput.createApplication.agentBindHqId")
                )}
                onSelect={(a) => {
                  formMethods.setValue("bindHqInput.createApplication.agentBindHqId", a.id);
                }}
                toValue={(a) => `${a.firstName} ${a.lastName} ${a.id}`}
                toLabel={(a) => `${a.firstName} ${a.lastName}`}
                buttonProps={{ variant: "outline", display: "flex" }}
              />
            </Cell>
            <Cell>
              <Label>BindHQ Insured</Label>
              <Error error={formMethods.formState.errors.insured?.bindHqId} />
              <Autocomplete
                placeholder={loadingPlaceholder(bindHqInsuredsLoading, "Select an Option")}
                options={bindHqInsuredOptions}
                selected={bindHqInsuredOptions.find((a) => a.id === formMethods.watch("insured.bindHqId"))}
                onSelect={(a) => {
                  formMethods.setValue("insured.bindHqId", a.id);
                }}
                // ${a.id} added in case insured names are not unique; insureds with idential names would both show as selected
                toValue={(a) => `${a.name} ${a.id}`}
                toLabel={(a) => a.name}
                buttonProps={{ variant: "outline", display: "flex" }}
              />
            </Cell>
          </Grid>
          {requireAscendFields && (
            <>
              <Grid className="grid-cols-2 gap-y-2">
                <Cell>
                  <Label>Agency Billing Contact First Name</Label>
                  <Input name="updateBindHqAgency.billingContactFirstName" />
                </Cell>
                <Cell>
                  <Label>Agency Billing Contact Last Name</Label>
                  <Input name="updateBindHqAgency.billingContactLastName" />
                </Cell>
              </Grid>
              <Grid className="grid-cols-2 gap-y-2">
                <Cell>
                  <Label>Agency Billing Phone*</Label>
                  <Input name="updateBindHqAgency.phone" required={true} />
                </Cell>
                <Cell>
                  <Label>Agency Billing Email*</Label>
                  <Input name="updateBindHqAgency.email" required={true} />
                </Cell>
              </Grid>
              <Grid className="grid-cols-3 gap-y-2">
                <Cell>
                  <Label>Agency Billing Address Line 1*</Label>
                  <Input name="updateBindHqAgency.line1" required={true} />
                </Cell>
                <Cell>
                  <Label>Agency Billing Address Line 2</Label>
                  <Input name="updateBindHqAgency.line2" />
                </Cell>
                <Cell>
                  <Label>Agency Billing Address City*</Label>
                  <Input name="updateBindHqAgency.city" required={true} />
                </Cell>
                <Cell>
                  <Label>Agency Billing Address State*</Label>
                  <SelectUSState control={formMethods.control} fieldName="updateBindHqAgency.state" required={true} />
                </Cell>
                <Cell>
                  <Label>Agency Billing Address Zip*</Label>
                  <Input name="updateBindHqAgency.zip" required={true} />
                </Cell>
              </Grid>
            </>
          )}

          {createNewInsuredSelected && (
            <div className="bg-background border-y py-6 space-y-6">
              <h4 className="mx-6">Create New Insured</h4>
              <Grid className="grid-cols-3">
                <Cell>
                  <Label>Business Name*</Label>
                  <Input placeholder="Business Name (Required)" required={true} name="insured.name" />
                </Cell>
                <Cell>
                  <Label>DBA</Label>
                  <Input placeholder="DBA" name="DBA" />
                </Cell>
                <Cell>
                  <Label>Legal Entity Type*</Label>
                  <Error error={formMethods.formState.errors.insured?.legalEntityType} />
                  <SelectEnum
                    control={formMethods.control}
                    fieldName="insured.legalEntityType"
                    enumType={BindHqInsuredType}
                  />
                </Cell>
              </Grid>
              <Grid className="grid-cols-4 gap-y-4">
                <Label className="col-span-full">Client Exposure Address *</Label>
                <Input
                  placeholder="Street Line 1*"
                  required={true}
                  name={`clientData.${KnownClientData.MailingAddress.AddressLine1}`}
                  className="col-span-2"
                />
                <Input
                  placeholder="Street Line 2"
                  name={`clientData.${KnownClientData.MailingAddress.AddressLine2}`}
                  className="col-span-2"
                />
                <Input
                  placeholder="City*"
                  required={true}
                  name={`clientData.${KnownClientData.MailingAddress.City}`}
                  className="col-span-2"
                />
                <div className="col-span-1">
                  <SelectUSState
                    control={formMethods.control}
                    fieldName={`clientData.${KnownClientData.MailingAddress.State}`}
                    required={true}
                  />
                </div>
                <div className="col-span-1">
                  <Input
                    placeholder="Zip Code*"
                    required={true}
                    name={`clientData.${KnownClientData.MailingAddress.Zip}`}
                  />
                </div>
              </Grid>
            </div>
          )}
          <Grid className="grid-cols-3">
            <Cell>
              <Label>Effective Date*</Label>
              <DatePickerWithError
                dateFormat="MMMM d, yyyy"
                formMethods={formMethods}
                fieldName="bindHqInput.createPolicy.effectiveDate"
                onSelect={(e) => {
                  if (e) {
                    // Set the expiration date to one year after the effective date
                    const oneYearLater = Dates.incrementDate({ date: e, addYears: 1 }).toISOString();
                    !formMethods.getValues("bindHqInput.createPolicy.expirationDate") &&
                      formMethods.setValue("bindHqInput.createPolicy.expirationDate", oneYearLater);
                    // Set policy invoice due date based on effective date
                    const tenDaysLater = Dates.incrementDate({ date: e, addDays: 10 }).toISOString();
                    const twentyDaysLater = Dates.incrementDate({ date: e, addDays: 20 }).toISOString();
                    const invoiceDueDate =
                      appetiteProduct?.marketType === MarketTypes.BindingAuthority ? twentyDaysLater : tenDaysLater;
                    !formMethods.getValues("bindHqInput.createPolicy.invoiceDueDate") &&
                      formMethods.setValue("bindHqInput.createPolicy.invoiceDueDate", invoiceDueDate);
                  }
                }}
              />
            </Cell>
            <Cell>
              <Label>Expiration Date*</Label>
              <DatePickerWithError
                dateFormat="MMMM d, yyyy"
                formMethods={formMethods}
                fieldName="bindHqInput.createPolicy.expirationDate"
              />
            </Cell>
            <Cell>
              <Label>Application Type*</Label>
              <Error error={formMethods.formState.errors.bindHqInput?.createApplication?.type as FieldError} />
              <SelectEnum
                control={formMethods.control}
                fieldName="bindHqInput.createApplication.type"
                enumType={BindHqApplicationType}
              />
            </Cell>
          </Grid>
          <div className="border-t pt-6 space-y-6">
            <h4 className="mx-6">BindHQ</h4>
            <Grid className="grid-cols-1">
              <Cell>
                <Label>BindHQ Lines of Business*</Label>
                <Autocomplete
                  placeholder={
                    selectedBindHqLines?.length > 0 ? (
                      <BindHQLinesOfBusiness
                        lines={formMethods.watch("bindHqInput.createApplication.linesOfBusiness")}
                        onDelete={(line) =>
                          formMethods.setValue(
                            "bindHqInput.createApplication.linesOfBusiness",
                            selectedBindHqLines.filter((l) => l !== line)
                          )
                        }
                      />
                    ) : (
                      "Select Lines"
                    )
                  }
                  // Sort the submission's lines to the top of the options list (and Pkg because it's so common)
                  options={sortBy(Object.values(BindHqLineOfBusiness), (l) =>
                    bindHqLinesOnSubmission.includes(l) || l === BindHqLineOfBusiness.Pkg ? -1 : 0
                  )}
                  onSelect={(l) =>
                    formMethods.setValue(
                      "bindHqInput.createApplication.linesOfBusiness",
                      uniq([...selectedBindHqLines, l])
                    )
                  }
                  toValue={(l) => BindHqLineOfBusinessDisplayName[l]}
                  toLabel={(l) => BindHqLineOfBusinessDisplayName[l]}
                  buttonProps={{ variant: "outline", display: "flex" }}
                />
              </Cell>
            </Grid>
            <Grid className="grid-cols-2">
              <Cell>
                <Label>BindHQ Marketing Company*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.marketingCompanyId} />
                <Autocomplete
                  placeholder={loadingPlaceholder(bindHqMarketingCompaniesLoading, "Search BindHQ Marketing Companies")}
                  options={bindHqMarketingCompanies}
                  selected={bindHqMarketingCompanies.find(
                    (m) => m.id === formMethods.watch("bindHqInput.createQuote.marketingCompanyId")
                  )}
                  onSelect={(m) => formMethods.setValue("bindHqInput.createQuote.marketingCompanyId", m.id)}
                  toValue={(m) => m.name}
                  toLabel={(m) => m.name}
                  buttonProps={{ variant: "outline", display: "flex" }}
                />
              </Cell>
              <Cell>
                <Label>BindHQ Carrier*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.carrierId} />
                <Autocomplete
                  placeholder={loadingPlaceholder(bindHqCarriersLoading, "Search BindHQ Carriers")}
                  // Sort the carriers for this appetite product to the top of the options list
                  options={sortBy(bindHqCarriers, (c) => (appetiteProduct?.bindHqCarrierIds.includes(c.id) ? -1 : 0))}
                  selected={bindHqCarriers.find((c) => c.id === formMethods.watch("bindHqInput.createQuote.carrierId"))}
                  onSelect={(c) => formMethods.setValue("bindHqInput.createQuote.carrierId", c.id)}
                  toValue={(c) => c.name}
                  toLabel={(c) => c.name}
                  buttonProps={{ variant: "outline", display: "flex" }}
                />
              </Cell>
            </Grid>
            <Grid className="grid-cols-4">
              <Cell>
                <Label>Business Type*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.businessType} />
                <SelectEnum
                  control={formMethods.control}
                  fieldName="bindHqInput.createQuote.businessType"
                  enumType={BindHqQuoteBusinessType}
                />
              </Cell>
              <Cell>
                <Label>Billing Type*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.billingType} />
                <SelectEnum
                  control={formMethods.control}
                  fieldName="bindHqInput.createQuote.billingType"
                  enumType={BindHqQuoteBillingType}
                  onChange={(value) => {
                    if (value === BindHqQuoteBillingType.AgencyBill) {
                      formMethods.setValue("bindHqInput.createQuote.taxesCollectedBy", BindHqQuoteTaxesCollectedBy.Mga);
                    }
                    if (value === BindHqQuoteBillingType.DirectBill) {
                      formMethods.setValue(
                        "bindHqInput.createQuote.taxesCollectedBy",
                        BindHqQuoteTaxesCollectedBy.MarketingCompany
                      );
                    }
                  }}
                />
              </Cell>
              <Cell>
                <Label>Taxes Collected By*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.taxesCollectedBy} />
                <SelectEnum
                  control={formMethods.control}
                  fieldName="bindHqInput.createQuote.taxesCollectedBy"
                  enumType={BindHqQuoteTaxesCollectedBy}
                />
              </Cell>
              <Cell>
                <Label>Taxation Home State*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.taxationHomeState} />
                <SelectUSState control={formMethods.control} fieldName="bindHqInput.createQuote.taxationHomeState" />
              </Cell>
            </Grid>
            <Grid className="grid-cols-3">
              <Cell>
                <Label>Minimum Earned Premium %*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.minimumEarnedPremiumPercentage} />
                <Input placeholder="Enter a Percentage" name="bindHqInput.createQuote.minimumEarnedPremiumPercentage" />
              </Cell>
              <Cell>
                <Label>Gross Commission %*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.grossCommissionPercentage} />
                <Input placeholder="Enter a Percentage" name="bindHqInput.createQuote.grossCommissionPercentage" />
              </Cell>
              <Cell>
                <Label>Agent Commission %*</Label>
                <Error error={formMethods.formState.errors.bindHqInput?.createQuote?.agentCommissionPercentage} />
                <Input placeholder="Enter a Percentage" name="bindHqInput.createQuote.agentCommissionPercentage" />
              </Cell>
            </Grid>
          </div>
          <div className="border-t pt-6 space-y-2">
            <h4 className="mx-6">Quote Line Items / Fees</h4>
            <Grid className="grid-cols-[1fr_1fr_1fr_1fr_1.5rem]">
              <GridRowHeader>
                <Cell>Type</Cell>
                <Cell>Amount ($)</Cell>
                <Cell>BindHQ Line of Business</Cell>
                <Cell>Description</Cell>
                <Button
                  variant="outline"
                  size="icon-sm"
                  onClick={() => {
                    formMethods.setValue("bindHqInput.createQuote.lineItems", [
                      ...(formMethods.getValues("bindHqInput.createQuote.lineItems") ?? []),
                      {
                        type: BindHqQuoteLineItemType.Premium,
                        amount: "",
                        lineOfBusiness:
                          selectedBindHqLines.length === 1
                            ? selectedBindHqLines[0]
                            : (undefined as unknown as BindHqLineOfBusiness), // If there are multiple lines, don't pre-select a line
                      },
                    ]);
                  }}
                >
                  <Icon icon="add_2" />
                </Button>
              </GridRowHeader>
              {(formMethods.watch("bindHqInput.createQuote.lineItems") ?? []).map((_lineItem, index) => (
                <GridRow key={index}>
                  <Cell>
                    <SelectEnum
                      control={formMethods.control}
                      fieldName={`bindHqInput.createQuote.lineItems.${index}.type`}
                      enumType={BindHqQuoteLineItemType}
                      onChange={(value) => {
                        if (value === BindHqQuoteLineItemType.MgaFee) {
                          formMethods.setValue(
                            `bindHqInput.createQuote.lineItems.${index}.description`,
                            "Wholesale Broker Fee"
                          );
                        }
                      }}
                    />
                  </Cell>
                  <Cell>
                    <Currency
                      control={formMethods.control}
                      name={`bindHqInput.createQuote.lineItems.${index}.amount`}
                      required={true}
                      placeholder="Amount"
                      allowDecimal={true}
                    />
                  </Cell>
                  <Cell>
                    <FormField
                      control={formMethods.control}
                      name={`bindHqInput.createQuote.lineItems.${index}.lineOfBusiness`}
                      render={({ field }) => (
                        <FormItem className="flex-auto">
                          <Select
                            key={field.value}
                            onValueChange={(value) => field.onChange(value as unknown as BindHqLineOfBusiness)}
                            value={field.value}
                          >
                            <SelectTrigger>
                              <SelectValue
                                placeholder={
                                  <>
                                    Select a Line
                                    <Error
                                      error={
                                        formMethods.formState.errors.bindHqInput?.createQuote?.lineItems?.[index]
                                          ?.lineOfBusiness
                                      }
                                    />
                                  </>
                                }
                              />
                            </SelectTrigger>
                            <SelectContent>
                              {selectedBindHqLines?.map((line) => (
                                <SelectItem key={line} value={line}>
                                  {BindHqLineOfBusinessDisplayName[line]}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </Cell>
                  <Cell>
                    <Input placeholder="Description" name={`bindHqInput.createQuote.lineItems.${index}.description`} />
                  </Cell>
                  <Cell>
                    <Button
                      variant="ghost"
                      size="icon-sm"
                      onClick={() => {
                        formMethods.setValue(
                          "bindHqInput.createQuote.lineItems",
                          formMethods.getValues("bindHqInput.createQuote.lineItems").filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <Icon icon="delete" />
                    </Button>
                  </Cell>
                </GridRow>
              ))}
            </Grid>
          </div>
          <Grid className="grid-cols-3">
            <Cell>
              <Label>Policy Number*</Label>
              <Error error={formMethods.formState.errors.bindHqInput?.createPolicy?.policyNumber} />
              <Input placeholder="Policy Number" name="bindHqInput.createPolicy.policyNumber" />
            </Cell>
            <Cell>
              <Label>Invoice Due Date*</Label>
              <DatePickerWithError formMethods={formMethods} fieldName="bindHqInput.createPolicy.invoiceDueDate" />
            </Cell>
            <Cell className="self-end">
              <Button form={formId}>Submit to BindHQ {submitting && <Spinner />}</Button>
            </Cell>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

const Cell = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col gap-2", className)} {...rest}>
    {children}
  </div>
);

const loadingPlaceholder = (loading: boolean, placeholder: string) => (loading ? <Loading /> : placeholder);

/**
 * Using this component instead of setting fields as required via params in individual field inputs because:
 * 1) some inputs don't accept the required prop (ex: Autocomplete)
 * 2) the error popups sometimes don't render in the right location on the page, so they're not very useful. Not sure why.
 */
const Error = ({ error }: { error?: FieldError }) =>
  error?.message ? <span className="text-destructive text-xs ml-2">{error.message}</span> : null;
