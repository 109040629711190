import { QueryHookOptions } from "@apollo/client";
import { useParams } from "react-router";

import { Exact, InsuredQuery, useInsuredQuery } from "src/generated/graphql";

export const useInsured = (options?: QueryHookOptions<InsuredQuery, Exact<{ id: string }>>) => {
  const { insuredId } = useParams<"insuredId">();
  const { data, loading, error, refetch } = useInsuredQuery({
    variables: { id: insuredId! },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
    ...options,
  });

  return {
    insured: data?.insured,
    loading,
    error,
    refetch,
  };
};
