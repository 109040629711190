import { MarketingPlanState, OpportunityDetailsFragment } from "src/generated/graphql";
import { Complete } from "./complete";
import { InformationReview } from "./information-review";
import { Marketing } from "./marketing";

export const MarketingPlanActions = ({ marketingPlan }: { marketingPlan: OpportunityDetailsFragment }) => {
  let content;

  switch (marketingPlan.state) {
    case MarketingPlanState.InformationReview:
      content = <InformationReview marketingPlan={marketingPlan} />;
      break;
    case MarketingPlanState.Marketing:
      content = <Marketing marketingPlan={marketingPlan} />;
      break;
    case MarketingPlanState.Complete:
      content = <Complete marketingPlan={marketingPlan} />;
      break;
    default:
      return null;
  }

  return content;
};
