import { Handle, NodeProps, Position, ReactFlowState, useStore } from "@xyflow/react";
import { FC, MouseEvent, useEffect, useState } from "react";

import { Badge, BadgeProps } from "@/components/ui/badge";
import { Icon, type IconString } from "@/components/ui/icon";
import { cn } from "src/utils";

import { NodeAction, NodeToolbar } from "./node-toolbar";

export interface NodeBaseProps extends Omit<NodeProps, "data"> {
  label?: string;
  icon?: IconString;
  actions?: NodeAction[];
  badge?: BadgeProps;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  isRoot?: boolean;
  isActive?: boolean;
  hasChildren?: boolean;
}

const zoomSelector = (s: ReactFlowState) => s.transform[2] >= 0.74;

export const NodeBase: FC<NodeBaseProps> = ({
  label,
  icon,
  actions,
  badge,
  onClick,
  isRoot,
  isActive,
  hasChildren,
}) => {
  const showBadgeContent = useStore(zoomSelector);
  const [isToolbarVisible, setIsToolbarVisible] = useState<boolean | undefined>(isActive);

  useEffect(() => {
    setIsToolbarVisible(isActive);
  }, [isActive]);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (actions?.length) {
      const defaultAction = actions?.find((action) => action.isDefault);

      defaultAction?.onClick?.(event);
    }

    onClick?.(event);
  };

  return (
    <div className="[pointer-events:all] cursor-pointer relative [&_.react-flow\_\_handle]:!bg-muted-foreground [&_.react-flow\_\_handle]:!border-background [&_.react-flow\_\_handle]:border-4 [&_.react-flow\_\_handle]:w-3 [&_.react-flow\_\_handle]:h-3 [&_.react-flow\_\_handle]:z-10">
      {!isRoot && <Handle type="target" position={Position.Left} className="bg-heavy" />}

      <div
        onClick={handleClick}
        className={cn(
          `z-1 group hover:bg-accent hover:text-accent-foreground hover:-translate-y-[2px] transition-all flex flex-row items-center gap-3 bg-card rounded-md p-3 pr-5 max-w-72 shadow-sm hover:shadow-md before:-inset-[1px] before:border before:border-heavy before:transition-colors before:content-[''] before:absolute before:rounded-md before:pointer-events-none`,
          { "before:border-primary filled is-active -translate-y-[2px]": isActive }
        )}
      >
        {!!icon && (
          <div className="text-muted-foreground group-hover:text-foreground flex items-center justify-center self-start border border-heavy bg-transparent rounded-md h-10 w-10 flex-grow-0 flex-shrink-0 group-[.is-active]:bg-primary group-[.is-active]:border-primary group-[.is-active]:text-primary-foreground">
            <Icon icon={icon} className="text-2xl" />
          </div>
        )}

        {badge && (
          <div className={cn("absolute z-10 -top-3.5 -right-2", { "-top-2 -right-2": !showBadgeContent })}>
            <Badge {...badge} className={cn("px-[0.66em]", { "h-5 w-5": !showBadgeContent }, badge.className)}>
              {showBadgeContent && <>{badge?.children}</>}
            </Badge>
          </div>
        )}

        <div className="leading-tight break-words w-[calc(100%_-_52px)]">{label}</div>
      </div>

      {hasChildren && <Handle type="source" position={Position.Right} className="bg-heavy" />}

      {!!actions?.length && <NodeToolbar actions={actions} isVisible={isToolbarVisible} />}
    </div>
  );
};
