import { useState } from "react";
import { Outlet } from "react-router";
import { useDebounce } from "react-use";
import { useDocumentTitle } from "usehooks-ts";

import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { Pagination, PaginationProvider, usePagination } from "@/components/pagination";
import { ScrollPane } from "@/components/scroll-pane";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { SearchInput } from "@/components/ui/input";
import { Spinner } from "@/components/ui/loading";
import { useMyAccount } from "@/hooks/use-my-account";
import { usePaginatedAgenciesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const Launch = () => (
  <PaginationProvider>
    <ScrollPane>
      <Outlet />
    </ScrollPane>
  </PaginationProvider>
);

export const LaunchAgencies = () => {
  const { data: user } = useMyAccount();
  const { setNumPages, numRecords, setNumRecords, page, rowsPerPage } = usePagination();
  const [searchTerm, setSearchTerm] = useState("");
  const [term, setTerm] = useState("");

  useDebounce(
    () => {
      setTerm(searchTerm);
    },
    222,
    [searchTerm]
  );

  const { data: { paginatedAgencies: { agencies } } = { paginatedAgencies: { agencies: [] } }, loading } =
    usePaginatedAgenciesQuery({
      variables: {
        args: {
          term,
          userId: user?.id,
        },
        pagination: {
          page,
          rowsPerPage: Number(rowsPerPage),
        },
      },
      onCompleted: (data) => {
        setNumPages(data.paginatedAgencies.numPages);
        setNumRecords(data.paginatedAgencies.numRecords);
      },
    });

  useDocumentTitle("Launch: Agencies");

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1 className="mr-auto">Agency Management</h1>
          {loading && <Spinner />}
          <SearchInput
            name="search"
            placeholder="Agencies or Agents"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.currentTarget.value)}
          />
        </SectionTitle>
      </SectionHeader>
      <Grid className="grid-cols-3">
        <GridRowHeader>
          <GridCell>Agency</GridCell>
          <GridCell>Owner</GridCell>
          <GridCell>Created</GridCell>
        </GridRowHeader>
        {agencies.map((agency) => {
          const { id, isTest, createdAt, name, owner } = agency;
          return (
            <GridRowLink key={id} to={id}>
              <GridCell className="flex gap-2">
                {name}
                {isTest && <Badge>Test</Badge>}
              </GridCell>
              <GridCell>{owner && `${owner.firstName} ${owner.lastName}`}</GridCell>
              <GridCell>{formatTimezoneDateString(createdAt, "MMM d, yyyy")}</GridCell>
            </GridRowLink>
          );
        })}
      </Grid>
      <Pagination label={numRecords === 1 ? "Agency" : "Agencies"} />
    </>
  );
};
