import { Button } from "@/components/ui/button";
import {
  MarketingPlanStateInformationGatheringActions,
  useTransitionMarketingPlanMutation,
} from "src/generated/graphql";
import { RequirementsContextData } from "../requirements";

export const InformationGathering = ({ opportunity }: RequirementsContextData) => {
  const [transition] = useTransitionMarketingPlanMutation();

  if (!opportunity) {
    return null;
  }

  const handleTransition = async (action: MarketingPlanStateInformationGatheringActions) => {
    await transition({
      variables: {
        input: {
          marketingPlanId: opportunity.id,
          action,
        },
      },
    });
  };

  return (
    <Button
      variant="outline"
      size="xs"
      onClick={() => handleTransition(MarketingPlanStateInformationGatheringActions.ReviewInformation)}
    >
      Info Gathering Complete
    </Button>
  );
};
