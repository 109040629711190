import { useMatches } from "react-router";
import { Fragment } from "react/jsx-runtime";

import { cn } from "src/utils";
import { Icon } from "./ui/icon";

export interface Handle {
  crumb: React.ReactNode;
  menu: React.ReactNode;
}

export const Breadcrumbs = ({ className }: { className?: string }) => {
  const matches = useMatches();

  const crumbs = matches
    .filter((match) => Boolean(match.handle && (match.handle as Handle).crumb))
    .map((match) => (match.handle as Handle).crumb);

  return (
    <div
      className={cn("flex flex-auto gap-1.5 items-center mr-auto text-muted-foreground text-2xs truncate", className)}
    >
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <Icon icon="chevron_right" className="first:hidden flex-none mt-px opacity-50 text-sm" />
          <span className="truncate">{crumb}</span>
        </Fragment>
      ))}
    </div>
  );
};
