import { UUID } from "@cp/toolkit";
import * as Select from "@radix-ui/react-select";

import { HasInternalRole } from "@/components/has-role";
import { Avatar } from "@/components/ui/avatar";
import { SelectContent, SelectItem } from "@/components/ui/select";
import { useBrokerAccountsQuery, UserAccountFragment } from "src/generated/graphql";

type Broker = Pick<UserAccountFragment, "id" | "firstName" | "lastName"> | null;

export const SetBroker = ({ broker, onSelect }: { broker?: Broker; onSelect: (id: UUID) => void }) => {
  const { data: brokers } = useBrokerAccountsQuery({
    fetchPolicy: "cache-first",
  });

  return (
    <HasInternalRole elseShow={<Avatar user={broker} />}>
      <Select.Root value={broker ? broker.id : "-"} onValueChange={onSelect}>
        <Select.Trigger>
          <Avatar user={broker} />
        </Select.Trigger>
        <SelectContent>
          <SelectItem value="-">Unassigned</SelectItem>
          {brokers?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );
};
