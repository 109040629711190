import { formatMoney, STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Input } from "@/forms/default";
import { RequirementElementProps } from "@/requirements/requirement-elements/requirement-element-props";
import {
  InsuredPremiseBuildingFragment,
  InsuredPremiseLocationFragment,
  useCreateInsuredPremiseBuildingMutation,
  useCreateInsuredPremiseLocationMutation,
  useDeleteInsuredPremiseBuildingMutation,
  useDeleteInsuredPremiseLocationMutation,
  useEditInsuredPremiseBuildingMutation,
  useEditInsuredPremiseLocationMutation,
  useInsuredPremiseLocationsQuery,
} from "src/generated/graphql";
import { Editor, EditorCount, EditorHeader, EditorItem, EditorRow } from "./editor";
import { useModal } from "./modal-provider";
import { Autocomplete } from "./ui/autocomplete";
import { Button } from "./ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./ui/collapsible";
import { Icon } from "./ui/icon";
import { Loading } from "./ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { useToast } from "./ui/use-toast";

const WriteLocationSchema = z.object({
  locationNumber: z.string().transform((a) => Number.parseInt(a)),
  city: z.string().min(2),
  state: z.string(),
  zip: z.string().min(5),
  county: z.string().min(2),
});

const WriteBuildingSchema = z.object({
  locationId: z.string(),
  buildingNumber: z
    .string()
    .or(z.number())
    .transform((a) => Number.parseInt(a.toString())),
  description: z.string().optional(),
  addressLine1: z.string().min(2),
  addressLine2: z.string().optional(),
  cityLimits: z.string().optional(),
  interest: z.string().optional(),
  fullTimeEmployeeCount: z
    .string()
    .optional()
    .transform((a) => (a ? Number.parseInt(a) : undefined)),
  partTimeEmployeeCount: z
    .string()
    .optional()
    .transform((a) => (a ? Number.parseInt(a) : undefined)),
  annualRevenue: z
    .string()
    .min(1)
    .transform((a) => Number.parseFloat(a.replaceAll("$", "").replaceAll(",", "")).toFixed(2).toString()),
  occupiedArea: z
    .string()
    .optional()
    .transform((a) => (a ? Number.parseInt(a) : undefined)),
  openToPublicArea: z
    .string()
    .optional()
    .transform((a) => (a ? Number.parseInt(a) : undefined)),
  totalBuildingArea: z
    .string()
    .optional()
    .transform((a) => (a ? Number.parseInt(a) : undefined)),
  anyAreaLeasedToOthers: z.boolean().optional(),
});

export function LocationEditor({
  insuredId,
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: { insuredId: string } & RequirementElementProps) {
  const { openModal, openForm } = useModal();
  const { toast } = useToast();

  const { data, loading, refetch } = useInsuredPremiseLocationsQuery({ variables: { id: insuredId } });

  useEffect(() => {
    const noLocation = !data?.locations || data.locations.length === 0;
    const noBuildings = data?.locations.every((location) => location.buildings.length === 0) ?? true;

    if (noLocation) {
      onSubmissionRequirementsNotMet("Add at least 1 location");
    } else if (noBuildings) {
      onSubmissionRequirementsNotMet("Add at least 1 building");
    } else {
      onSubmissionRequirementsMet();
    }
  }, [data?.locations]);

  const [createLocationTrigger] = useCreateInsuredPremiseLocationMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Location created" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error creating location: duplicate location number" });
      } else {
        toast({ title: "Error creating location" });
      }
    },
  });

  const [createBuildingTrigger] = useCreateInsuredPremiseBuildingMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Building created" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: `Error creating building: duplicate location number` });
      } else {
        toast({ title: `Error creating building` });
      }
    },
  });

  const [editLocationTrigger] = useEditInsuredPremiseLocationMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Location edited" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error editing location: duplicate location number" });
      } else {
        toast({ title: "Error editing location" });
      }
    },
  });

  const [editBuildingTrigger] = useEditInsuredPremiseBuildingMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Building edited" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error editing building: duplicate location number" });
      } else {
        toast({ title: "Error editing building" });
      }
    },
  });

  const [deleteLocationTrigger] = useDeleteInsuredPremiseLocationMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Location deleted" });
    },
    onError() {
      toast({ title: "Error deleting location" });
    },
  });

  const [deleteBuildingTrigger] = useDeleteInsuredPremiseBuildingMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Building deleted" });
    },
    onError() {
      toast({ title: "Error deleting building" });
    },
  });

  if (loading || !data || !data.locations) {
    return <Loading />;
  }

  const locations = data.locations;

  const createLocation = async () => {
    const fd = await openForm(WriteLocationSchema, <WriteLocationForm title="Add Location" />);
    if (!fd) {
      return;
    }

    await createLocationTrigger({
      variables: {
        input: {
          ...fd,
          insuredId,
        },
      },
    });
  };

  const createBuilding = async (locationId: string) => {
    const fd = await openForm(WriteBuildingSchema, <WriteBuildingForm title="Add Building" locations={locations} />, {
      defaultValues: { locationId },
    });
    if (!fd) {
      return;
    }

    await createBuildingTrigger({
      variables: {
        input: fd,
      },
    });
  };

  const editLocation = async (location: InsuredPremiseLocationFragment) => {
    const fd = await openForm(WriteLocationSchema, <WriteLocationForm title="Edit Location" />, {
      defaultValues: {
        locationNumber: location.locationNumber,
        city: location.city ?? undefined,
        state: location.state ?? undefined,
        zip: location.zip ?? undefined,
        county: location.county ?? undefined,
      },
    });
    if (!fd) {
      return;
    }

    await editLocationTrigger({
      variables: {
        input: {
          ...fd,
          locationId: location.id,
        },
      },
    });
  };

  const editBuilding = async (building: InsuredPremiseBuildingFragment) => {
    const fd = await openForm(WriteBuildingSchema, <WriteBuildingForm title="Edit Building" locations={locations} />, {
      defaultValues: {
        locationId: building.locationId,
        buildingNumber: building.buildingNumber,
        description: building.description ?? undefined,
        addressLine1: building.addressLine1 ?? undefined,
        addressLine2: building.addressLine2 ?? undefined,
        annualRevenue: building.annualRevenue ?? undefined,
      },
    });
    if (!fd) {
      return;
    }

    await editBuildingTrigger({
      variables: {
        input: {
          ...fd,
          buildingId: building.id,
        },
      },
    });
  };

  return (
    <Editor>
      <EditorHeader
        title="Edit Premises Information"
        columns={[
          "Loc. #",
          "City",
          "State",
          "Zip",
          "County",
          "Violent Crime Score",
          "Property Crime Score",
          "# Buildings",
          "",
        ]}
      >
        <Button type="button" size="sm" variant="outline" onClick={createLocation}>
          <Icon icon="add_2" />
          <span>Location</span>
        </Button>
      </EditorHeader>
      {locations.map((location) => (
        <Collapsible key={location.locationNumber} asChild>
          <EditorItem>
            <EditorRow>
              <div>
                <EditorCount>L{location.locationNumber}</EditorCount>
              </div>
              <div>{location.city}</div>
              <div>{location.state}</div>
              <div>{location.zip}</div>
              <div>{location.county}</div>
              <div>{location.violentCrimeScore}</div>
              <div>{location.propertyCrimeScore}</div>
              <div className="flex gap">
                <CollapsibleTrigger asChild>
                  <Button size="sm" variant="outline" className="group" disabled={location.buildings.length === 0}>
                    <span className="text-xs">{location.buildings.length}</span>
                    <Icon icon="unfold_more" className="group-data-[state=open]:hidden" />
                    <Icon icon="unfold_less" className="group-data-[state=closed]:hidden" />
                  </Button>
                </CollapsibleTrigger>
                <Button type="button" size="sm" variant="outline" onClick={() => createBuilding(location.id)}>
                  <Icon icon="add_2" />
                  <span>Building</span>
                </Button>
              </div>
              <div className="flex gap justify-end">
                <Button type="button" size="icon-sm" variant="ghost" onClick={() => editLocation(location)}>
                  <Icon icon="edit" />
                </Button>
                <Button
                  type="button"
                  size="icon-sm"
                  variant="ghost"
                  onClick={() => deleteLocationTrigger({ variables: { id: location.id } })}
                >
                  <Icon icon="delete" />
                </Button>
              </div>
            </EditorRow>
            {location.buildings[0] && (
              <CollapsibleContent>
                <Editor className="border">
                  <EditorHeader
                    columns={["Bldg. #", "Address", "Annual Revenue", "TIV", "Description", "", ""]}
                  ></EditorHeader>
                  {location.buildings.map((building) => (
                    <EditorItem key={building.id}>
                      <EditorRow>
                        <div>
                          <EditorCount>
                            L{location.locationNumber} - B{building.buildingNumber}
                          </EditorCount>
                        </div>
                        <div className="truncate">{building.addressLine1}</div>
                        <div>{formatMoney(building.annualRevenue)}</div>
                        <div>{formatMoney(building.tiv)}</div>
                        <div className="col-span-2">{building.description}</div>
                        <div className="flex gap justify-end">
                          <Button
                            type="button"
                            variant="ghost"
                            size="icon-sm"
                            onClick={() => {
                              void openModal(() => (
                                <dl>
                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Full Time Employees</dt>
                                    <dd>{building.fullTimeEmployeeCount}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Part Time Employees</dt>
                                    <dd>{building.partTimeEmployeeCount}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Occupied Area</dt>
                                    <dd>{building.occupiedArea}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Total Building Area</dt>
                                    <dd>{building.totalBuildingArea}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Wiring Year</dt>
                                    <dd>{building.wiringYear}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Roofing Year</dt>
                                    <dd>{building.roofingYear}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Heating Year</dt>
                                    <dd>{building.heatingYear}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Plumbing Year</dt>
                                    <dd>{building.plumbingYear}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Roof Type</dt>
                                    <dd>{building.roofType}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Construction Type</dt>
                                    <dd>{building.constructionType}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Alarm Type</dt>
                                    <dd>{building.burglarAlarmType}</dd>
                                  </div>

                                  <div className="grid grid-cols-2 gap border-b p-2">
                                    <dt className="font-semibold">Sprinkler %</dt>
                                    <dd>{building.sprinklerPercent}</dd>
                                  </div>
                                </dl>
                              ));
                            }}
                          >
                            <Icon icon="database" className="filled" />
                          </Button>
                          <Button type="button" size="icon-sm" variant="ghost" onClick={() => editBuilding(building)}>
                            <Icon icon="edit" />
                          </Button>
                          <Button
                            type="button"
                            size="icon-sm"
                            variant="ghost"
                            onClick={() => deleteBuildingTrigger({ variables: { id: building.id } })}
                          >
                            <Icon icon="delete" />
                          </Button>
                        </div>
                      </EditorRow>
                    </EditorItem>
                  ))}
                </Editor>
              </CollapsibleContent>
            )}
          </EditorItem>
        </Collapsible>
      ))}
    </Editor>
  );
}

function WriteLocationForm({ title }: { title: string }) {
  const formMethods = useFormContext();
  const [selectedState, setSelectedState] = useState<{ label: string; value: string }>();

  return (
    <div className="flex flex-col gap-4">
      <h3>{title}</h3>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col">
          <h4>Location Number</h4>
          <Input type="number" name="locationNumber" required />
        </div>

        <div className="flex flex-col">
          <h4>City</h4>
          <Input name="city" required />
        </div>

        <div className="flex flex-col">
          <h4>State</h4>
          <Autocomplete
            align="start"
            side="right"
            options={[...STRICT_STATE_OPTIONS]}
            selected={selectedState}
            onSelect={(s) => {
              formMethods.setValue("state", s.value);
              setSelectedState(s);
            }}
            toValue={(s) => s.value}
            toLabel={(s) => s.label}
          />
        </div>

        <div className="flex flex-col">
          <h4>Zip</h4>
          <Input name="zip" required />
        </div>

        <div className="flex flex-col">
          <h4>County</h4>
          <Input name="county" required />
        </div>
      </div>

      <div className="flex justify-between">
        <Button type="reset" variant="destructive">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </div>
  );
}

function WriteBuildingForm({
  title,
  locations,
}: {
  title: string;
  locations: Array<{ id: string; locationNumber: number }>;
}) {
  const { getValues, setValue } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <h3>{title}</h3>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col">
          <h4>Location</h4>
          <Select defaultValue={getValues().locationId} onValueChange={(v) => setValue("locationId", v)} required>
            <SelectTrigger>
              <SelectValue placeholder="Set building location" />
            </SelectTrigger>
            <SelectContent>
              {locations.map((location) => (
                <SelectItem key={location.id} value={location.id}>
                  {location.locationNumber}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col">
          <h4>Building Number</h4>
          <Input type="number" name="buildingNumber" required />
        </div>

        <div className="flex flex-col">
          <h4>Description</h4>
          <Input name="description" required />
        </div>

        <div className="flex flex-col">
          <h4>Address Line 1</h4>
          <Input name="addressLine1" required />
        </div>

        <div className="flex flex-col">
          <h4>Address Line 2</h4>
          <Input name="addressLine2" required />
        </div>

        <div className="flex flex-col">
          <h4>Annual Revenue</h4>
          <Input name="annualRevenue" required />
        </div>
      </div>

      <div className="flex justify-between">
        <Button type="reset" variant="destructive">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </div>
  );
}
