import { Button } from "@/components/ui/button";
import {
  MarketingPlanStateInformationReviewActions,
  OpportunityDetailsFragment,
  useTransitionMarketingPlanMutation,
} from "src/generated/graphql";

export const InformationReview = ({ marketingPlan }: { marketingPlan: OpportunityDetailsFragment }) => {
  const [transition] = useTransitionMarketingPlanMutation();

  if (!marketingPlan) {
    return null;
  }

  const handleTransition = async (action: MarketingPlanStateInformationReviewActions) => {
    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlan.id,
          action,
        },
      },
    });
  };

  return (
    <Button
      variant="outline"
      size="xs"
      onClick={() => handleTransition(MarketingPlanStateInformationReviewActions.StartMarketing)}
    >
      Start Marketing
    </Button>
  );
};
