import { useAtom } from "jotai";

import { pinnedInsuredsAtom } from "@/atoms";
import { InsuredPin } from "@/components/insured-pin";
import { SidebarLink, SidebarSection } from "@/components/sidebar";
import { Loading } from "@/components/ui/loading";
import { useInsuredsQuery } from "src/generated/graphql";

export const PinnedInsureds = () => {
  const [pinnedInsuredIds] = useAtom(pinnedInsuredsAtom);

  const { data: { insureds } = { insureds: [] }, loading } = useInsuredsQuery({
    variables: {
      input: {
        ids: Object.keys(pinnedInsuredIds),
      },
    },
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    insureds[0] && (
      <SidebarSection>
        <h6 className="text-muted-foreground">Pinned Insureds</h6>
        <div className="space-y-1">
          {insureds.map((insured) => {
            const { id, name } = insured;
            return (
              <div key={id} className="flex gap-4 items-center justify-between">
                <SidebarLink to={`/insured/${id}`} className="flex-auto truncate">
                  {name}
                </SidebarLink>
                <InsuredPin id={id} />
              </div>
            );
          })}
        </div>
      </SidebarSection>
    )
  );
};
