import { Outlet, useNavigate, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Switch } from "@/components/ui/switch";
import { toast } from "@/components/ui/use-toast";
import { Link } from "react-router-dom";
import { useDeleteVerticalMutation, useUpdateVerticalMutation, useVerticalQuery } from "src/generated/graphql";
import { parseError } from "src/utils";

export const Vertical = () => {
  useDocumentTitle("Admin: Vertical");
  const { verticalId } = useParams();

  const { data: { vertical } = {}, loading } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });

  if (loading) {
    return <Loading />;
  }

  if (!vertical) {
    return null;
  }

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>{vertical.name}</h1>
        </SectionTitle>
        <SectionDescription>{vertical.description}</SectionDescription>
      </SectionHeader>
      <Bar>
        <BarLinks>
          <BarLink to="." end>
            Templates
          </BarLink>
          <BarLink to="appetite">Appetite</BarLink>
        </BarLinks>
      </Bar>
      <Outlet />
    </>
  );
};

export const VerticalCrumb = () => {
  const { verticalId } = useParams();

  const { data: { vertical } = {} } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });

  if (!vertical) {
    return null;
  }

  return <Link to={`/verticals/${vertical.id}`}>{vertical.name}</Link>;
};

export const VerticalMenu = () => {
  const { verticalId } = useParams();
  const navigate = useNavigate();

  const {
    data: { vertical } = {},
    refetch,
    loading,
  } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });

  const [update] = useUpdateVerticalMutation({
    onCompleted() {
      void refetch();
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (!vertical) {
    return null;
  }

  return (
    <div className="flex flex-row gap items-center">
      <Button variant="ghost" size="icon-sm" onClick={() => navigate("edit")}>
        <Icon icon="edit" />
      </Button>
      <DeleteVertical id={vertical.id} />
      <Switch
        className="text-xs"
        checked={vertical.enabled}
        onCheckedChange={(enabled) =>
          update({ variables: { input: { id: verticalId!, name: vertical.name, enabled } } })
        }
      />
    </div>
  );
};

const DeleteVertical = ({ id }: { id: string }) => {
  const [deleteVertical] = useDeleteVerticalMutation({
    onError: (error) =>
      toast({ title: "Error deleting vertical", description: parseError(error), variant: "destructive" }),
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon-sm">
          <Icon icon="delete" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
        </DialogHeader>
        <Button
          variant="destructive"
          onClick={() => deleteVertical({ variables: { id }, refetchQueries: ["Verticals"] })}
        >
          Delete
        </Button>
      </DialogContent>
    </Dialog>
  );
};
