import { Fragment } from "react";

import { Separator } from "@/components/ui/separator";
import { BankanQuoteColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollars } from "src/utils/strings";
import { BankanCard, BankanCardHeader, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";

type Group = BankanQuoteColumnQuery["bankanQuoteColumn"]["groupedQuotes"][number];

export const QuotesCard = ({ group, cardClassName }: { group: Group; cardClassName: string }) => {
  const { insuredId, insured, quotes } = group;
  return (
    <BankanCard className={cardClassName}>
      <BankanCardHeader insured={insured} />
      {quotes.map((quote) => {
        const { id, effectiveDate, premium, appetiteProduct, lastTransitionAt } = quote;
        return (
          <Fragment key={id}>
            <Separator />
            <BankanLink to={`/insured/${insuredId}/quotes/${id}`}>
              <BankanLinkText>
                <strong>{appetiteProduct.carrierName}: </strong>
                {appetiteProduct.carrierProductName}
              </BankanLinkText>
              <BankanLinkFooter>
                <div className="mr-auto">{formatTimezoneDateString(effectiveDate, "MMM d, yyyy")}</div>
                <div className="whitespace-nowrap">{formatDollars(premium ?? 0)}</div>
                <DaysSince date={lastTransitionAt} />
              </BankanLinkFooter>
            </BankanLink>
          </Fragment>
        );
      })}
    </BankanCard>
  );
};
