import { Link, LinkProps } from "react-router-dom";

import { InsuredPin } from "@/components/insured-pin";
import { Icon } from "@/components/ui/icon";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { cn } from "src/utils";

type Insured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["insured"];

export const BankanCard = ({ children, className }: { children?: React.ReactNode; className?: string }) => (
  <div
    className={cn(
      "bg-background rounded-lg outline outline-1 outline-foreground/25 hover:outline-offset-2 transition-all shadow-sm",
      className
    )}
  >
    {children}
  </div>
);

export const BankanCardHeader = ({ insured }: { insured: Insured }) => (
  <header className="px-4 py-3 space-y-1.5">
    <div className="flex gap-3 items-center justify-between text-xs">
      <span className="font-semibold truncate">{insured.name}</span>
      <InsuredPin id={insured.id} className="-my-1 -mr-1.5" />
    </div>
    <div className="text-2xs text-muted-foreground truncate">
      {insured.agent.firstName} {insured.agent.lastName}, {insured.agent.agency.name}
    </div>
  </header>
);

export const BankanLink = ({ children, className, to, ...rest }: LinkProps & { cardClassName?: string }) => (
  <Link to={to} className={cn("block px-4 py-3 space-y-1.5", className)} {...rest}>
    {children}
  </Link>
);

export const BankanLinkText = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("font-semibold text-xs truncate", className)} {...rest}>
    {children}
  </div>
);

export const BankanLinkFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("flex gap-3 items-center justify-between text-2xs text-muted-foreground", className)} {...rest}>
    {children}
  </footer>
);

const now = Date.now();

export const DaysSince = ({ date }: { date: string }) => (
  <div className="filled flex font-semibold gap-1 items-center text-2xs tracking-tight">
    <Icon icon="event" />
    {daysDelta(date)}
  </div>
);

export const daysDelta = (date: string) => Math.round((now - Date.parse(date)) / 86_400_400);
