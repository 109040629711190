import { Outlet, useParams } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { ScrollPane } from "@/components/scroll-pane";
import { SidebarTrigger } from "@/components/sidebar";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { useMyAccount } from "@/hooks/use-my-account";
import { useAgencyQuery } from "src/generated/graphql";
import { InsuredsSidebar } from "./insureds-sidebar";

export const Insureds = () => {
  const { data: user } = useMyAccount();
  const { agencyId } = useParams();

  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId ?? "",
    },
    skip: !agencyId,
  });

  const id = agencyId ?? user?.agencyId;
  const agencyName = agency?.name ?? user?.agency.name;

  useDocumentTitle(`${agencyName}: QuoteWell`);

  if (!user || !user.agency || !id) {
    return null;
  }

  return (
    <>
      <ScrollPane>
        <Bar>
          <BarLinks>
            <BarLink to="." end>
              Active Marketing Plans
            </BarLink>
            <BarLink to="all">All Insureds</BarLink>
          </BarLinks>
          <SidebarTrigger seperator={false} />
        </Bar>
        <Outlet context={[id]} />
      </ScrollPane>
      <InsuredsSidebar id={id} />
    </>
  );
};
