import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { PolicyFragment, useCreateProcessedBinderMutation, useQuoteQuery } from "../../../generated/graphql";
import { useModal } from "../../components/modal-provider";
import { Button } from "../../components/ui/button";
import { Spinner } from "../../components/ui/loading";
import { useToast } from "../../components/ui/use-toast";
import { TextArea } from "../../forms/default";

export const GenerateProcessedBinderButton = ({ policy }: { policy: PolicyFragment }) => {
  const { quoteId } = policy;
  const { openForm } = useModal();
  const { toast } = useToast();
  const [createBinder, { loading }] = useCreateProcessedBinderMutation();
  const { data } = useQuoteQuery({
    variables: {
      id: quoteId,
    },
  });

  if (!data) {
    return null;
  }

  const { quote } = data;

  const createQuoteMetadata = async () => {
    if (!quote.totalPremium) {
      toast({
        title:
          "Unable to generate binder cover letter due to missing fields on the quote object. Please create and upload the processed binder manually.",
        variant: "destructive",
      });
      return;
    }

    const fd = await openForm(
      z.object({
        outstandingSubjectivities: z.string().min(1, { message: "Outstanding subjectivities is required" }),
      }),
      <CreateProcessedBinderForm />,
      { defaultValues: { outstandingSubjectivities: quote.outstandingSubjectivities ?? "" } }
    );

    if (!fd) {
      return;
    }

    await createBinder({
      variables: {
        input: {
          policyId: policy.id,
          outstandingSubjectivities: fd.outstandingSubjectivities,
        },
      },
      onCompleted: () => {
        toast({ title: "Binder Processed" });
      },
    });
  };

  return (
    <Button onClick={createQuoteMetadata} variant="outline" size="sm" display="flex" disabled={loading}>
      {loading ? <Spinner /> : "Generate Binder Cover Letter"}
    </Button>
  );
};

function CreateProcessedBinderForm() {
  const { trigger, formState } = useFormContext();
  const errors = formState.errors;
  return (
    <div className="space-y-6">
      <h3>Binder</h3>
      <div className="space-y-2">
        <TextArea
          required={true}
          name="outstandingSubjectivities"
          placeholder="Outstanding subjectivities (enter N/A if none apply)"
          className="px-4"
        />
        <div className="text-xs text-destructive">{errors?.outstandingSubjectivities?.message?.toString()}</div>
      </div>
      <Button
        display="flex"
        onClick={async () => {
          await trigger();
        }}
      >
        Save
      </Button>
    </div>
  );
}
