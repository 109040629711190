import { FetchResult } from "@apollo/client";
import { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ContinueButton } from "@/components/continue-button";
import { ScrollPane } from "@/components/scroll-pane";
import { Sidebar, SidebarContent, SidebarHeader, SidebarTrigger } from "@/components/sidebar";
import { Bar } from "@/components/ui/bar";
import { Icon } from "@/components/ui/icon";
import { useMyAccount } from "@/hooks/use-my-account";
import { CreateInsuredAndAgentMutation, CreateInsuredMutation, File_Audience } from "src/generated/graphql";
import { uploadFiles } from "src/utils/file";
import { InsuredForm } from "./insured-form";
import { OrEmail, SubmissionTipCard, UploadTipCard } from "./submission-cards";
import { AgentFormSchema, useCreateInsured2 } from "./use-create-opportunity";
import { useLoadFromDoc } from "./use-load-from-doc";

export type SetValuesFn = (keyVals: Array<[string, string | undefined]>) => void;

export const New = () => {
  const { data: user } = useMyAccount();
  const [file, setFile] = useState<File>();

  const [submitting, setSubmitting] = useState(false);

  const { zodResolver, createInsured } = useCreateInsured2(user);

  const formMethods = useForm<z.infer<typeof AgentFormSchema>>({
    resolver: zodResolver,
  });

  const setFormValues: SetValuesFn = (keyVals) => {
    keyVals.forEach(([k, v]) => {
      formMethods.setValue(k as keyof z.infer<typeof AgentFormSchema>, v);
    });
    void formMethods.trigger();
  };

  const { parentProps, onClick, ...docUploadMethods } = useLoadFromDoc(setFormValues, setFile);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = formMethods.getValues();
      setSubmitting(true);
      void createInsured(values).then(async (res) => {
        let insuredId;

        if (isCreateInsuredAndAgent(res)) {
          insuredId = res.data?.createInsuredAndAgent.id; // Access BrokerCreateOpportunity properties
        } else if (isCreateInsured(res)) {
          insuredId = res.data?.createInsured.id; // Access AgentCreateOpportunity properties
        }

        if (file && insuredId) {
          await uploadFiles([file], insuredId, File_Audience.External, "MANUAL_UPLOAD");
        }

        setSubmitting(false);
      });
    }
  };

  return (
    <>
      <ScrollPane>
        <Bar>
          Create New Insured
          <SidebarTrigger seperator={false} />
        </Bar>
        <div {...parentProps} className="flex-auto">
          <InsuredForm formMethods={formMethods} uploadInProgress={docUploadMethods.loading} onSubmit={onSubmit} />
        </div>
        <Bar as="footer">
          <ContinueButton
            form="appetiteForm"
            disabled={!formMethods.formState.isValid || submitting}
            submitting={submitting}
            className="ml-auto"
          />
        </Bar>
      </ScrollPane>
      <Sidebar side="right" width="20rem">
        <SidebarHeader>Submission Tips</SidebarHeader>
        <SidebarContent>
          <SubmissionTipCard />
          <UploadTipCard clickToUpload={onClick} documentUploadMethods={docUploadMethods} />
          <OrEmail />
        </SidebarContent>
      </Sidebar>
      {docUploadMethods.dragging ? (
        <div className="absolute inset-0 opacity-70 bg-popover flex justify-center items-center z-20">
          <p className="mb-48 flex items-center gap-2 text-xl font-semibold ">
            <Icon icon="upload" className="animate-bounce" />
            Drop file to start upload
          </p>
        </div>
      ) : null}
    </>
  );
};

function isCreateInsuredAndAgent(
  res: FetchResult<CreateInsuredAndAgentMutation> | FetchResult<CreateInsuredMutation>
): res is FetchResult<CreateInsuredAndAgentMutation> {
  return (res as FetchResult<CreateInsuredAndAgentMutation>)?.data?.createInsuredAndAgent !== undefined;
}

function isCreateInsured(
  res: FetchResult<CreateInsuredAndAgentMutation> | FetchResult<CreateInsuredMutation>
): res is FetchResult<CreateInsuredMutation> {
  return (res as FetchResult<CreateInsuredMutation>)?.data?.createInsured !== undefined;
}
