import { useState } from "react";
import { z } from "zod";

import { EditorCount } from "@/components/editor";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Input } from "@/forms/default";
import {
  useUpdateVerticalMarketingPlanTemplateProductRankMutation,
  useUpdateVerticalMarketingPlanTemplateProductRulesMutation,
  useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
  VerticalMarketingPlanTemplateFragment,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";
import { cn } from "src/utils";
import EditRules from "./edit-rules";
import { RuleSchema } from "./rule";

export const TemplateHierarchy = ({
  template,
  refetch,
}: {
  template: VerticalMarketingPlanTemplateFragment;
  refetch: () => void;
}) => {
  const { openForm } = useModal();
  const [selected, setSelected] = useState("");

  const [updateVerticalMarketingPlanProductMarketRankTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRankMutation({
      onCompleted() {
        void refetch();
      },
    });

  const [updateVerticalMarketingPlanTemplateProductRulesTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRulesMutation({
      onCompleted() {
        void refetch();
      },
    });

  const [updateVerticalMarketingPlanTemplateProductSubmissionEmailTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation({
      onCompleted() {
        void refetch();
      },
    });

  const updateRules = async (product: VerticalMarketingPlanTemplateProductFragment, index: number) => {
    const rules = JSON.parse(product.rules);
    const fd = await openForm(
      z.object({
        rule: RuleSchema,
      }),
      <EditRules rule={rules[index][0]} />
    );

    if (!fd) {
      return;
    }

    const updatedRules = rules.map((rule: any, i: number) => {
      if (i !== index) {
        return rule;
      }

      return [fd.rule];
    });

    await updateVerticalMarketingPlanTemplateProductRulesTrigger({
      variables: { input: { id: product.id, rules: JSON.stringify(updatedRules) } },
    });
  };

  const updateSubmissionEmail = async (product: VerticalMarketingPlanTemplateProductFragment) => {
    const fd = await openForm(
      z.object({
        submissionEmail: z.string(),
      }),
      <EditSubmissionEmail />,
      {
        defaultValues: {
          submissionEmail: product.submissionEmail ?? "",
        },
      }
    );

    if (!fd) {
      return;
    }

    await updateVerticalMarketingPlanTemplateProductSubmissionEmailTrigger({
      variables: { input: { id: product.id, submissionEmail: fd.submissionEmail } },
    });
  };

  return (
    <div className="grid grid-cols-[1fr_1px_3fr] items-start">
      <Grid className="grid-cols-[1rem_2fr_1fr]">
        <GridRowHeader>
          <GridCell />
          <GridCell>Product</GridCell>
          <GridCell>Sub. Email</GridCell>
        </GridRowHeader>
        {template.products.map((product) => (
          <GridRow
            key={product.id}
            onClick={() => setSelected(product.id)}
            className={cn("cursor-pointer", product.id === selected && "bg-primary/5")}
          >
            <Tooltip>
              <TooltipTrigger>
                <EditorCount>{product.rank + 1}</EditorCount>
              </TooltipTrigger>
              <TooltipContent className="flex gap items-center px-1 py-1" side="bottom">
                <Button
                  variant="ghost"
                  size="icon-sm"
                  disabled={product.rank === 0}
                  onClick={() =>
                    updateVerticalMarketingPlanProductMarketRankTrigger({
                      variables: { input: { id: product.id, rank: product.rank - 1 } },
                    })
                  }
                >
                  <Icon icon="keyboard_arrow_up" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon-sm"
                  disabled={product.rank === template.products.length - 1}
                  onClick={() =>
                    updateVerticalMarketingPlanProductMarketRankTrigger({
                      variables: { input: { id: product.id, rank: product.rank + 1 } },
                    })
                  }
                >
                  <Icon icon="keyboard_arrow_down" />
                </Button>
              </TooltipContent>
            </Tooltip>
            <GridCell>
              {product.appetiteProduct.carrierName}
              <br />
              <span className="text-2xs text-muted-foreground">{product.appetiteProduct.carrierProductName}</span>
            </GridCell>
            <GridCell>
              <Button variant="outline" size="xs" onClick={() => updateSubmissionEmail(product)}>
                {product.submissionEmail ?? "N / A"}
              </Button>
            </GridCell>
          </GridRow>
        ))}
      </Grid>
      <Separator orientation="vertical" />
      {selected ? (
        <Grid className="grid-cols-[1fr_4fr_1fr_1fr_1.5rem]">
          <GridRowHeader>
            <GridCell>Type</GridCell>
            <GridCell>Rule</GridCell>
            <GridCell>Operator</GridCell>
            <GridCell>Value</GridCell>
            <div />
          </GridRowHeader>
          {template.products.map((product) =>
            JSON.parse(product.rules).flatMap((rule: any, i: number) => (
              <GridRow key={i} className={cn(product.id !== selected && "hidden")}>
                <GridCell>
                  <EditorCount>{rule[0].type}</EditorCount>
                </GridCell>
                <GridCell>{rule[0].key}</GridCell>
                <GridCell>{rule[0].operator}</GridCell>
                <GridCell>{rule[0].value}</GridCell>
                <Button variant="ghost" size="icon-sm" onClick={() => updateRules(product, i)}>
                  <Icon icon="edit" />
                </Button>
              </GridRow>
            ))
          )}
        </Grid>
      ) : (
        <div className="border-t mt-6" />
      )}
    </div>
  );
};

const EditSubmissionEmail = () => (
  <div>
    <Input name="submissionEmail" />
    <Button>Save</Button>
  </div>
);
