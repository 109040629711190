import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { MarketTypes, SubmissionFragment, SubmissionStateSubmittedActions } from "src/generated/graphql";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const Submitted = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleConfirmation, handleTransition } = useSubmissionActions();
  return (
    <SubmissionMenu submission={submission}>
      <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionStateSubmittedActions.BlockMarket)}>
        <Icon icon="front_hand" />
        Blocked
      </DropdownMenuItem>
      {submission.appetiteProduct.marketType === MarketTypes.BindingAuthority && (
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionStateSubmittedActions.Refer)}>
          <Icon icon="forward" />
          Mark as Referred
        </DropdownMenuItem>
      )}
      <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionStateSubmittedActions.Decline)}>
        <Icon icon="thumb_down" />
        Declined
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem onClick={() => handleConfirmation(submission, SubmissionStateSubmittedActions.RuleOut, true)}>
        <Icon icon="block" />
        Rule Out
      </DropdownMenuItem>
    </SubmissionMenu>
  );
};
