import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import {
  ComponentProps,
  createContext,
  FC,
  forwardRef,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useContext,
  useId,
} from "react";

import { Button } from "@/components/ui/button";
import { Icon, IconString } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "src/utils";

export type ToolbarPosition =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "top-left"
  | "left-top"
  | "top-right"
  | "right-top"
  | "bottom-left"
  | "left-bottom"
  | "bottom-right"
  | "right-bottom";

export type ToolbarOrientation = "horizontal" | "vertical";

export type ToolbarPopoverSide = "top" | "right" | "bottom" | "left";

export interface ToolbarContextValue {
  position?: ToolbarPosition;
  orientation?: ToolbarOrientation;
  popoverSide?: ToolbarPopoverSide;
}

const toolbarVariants = cva("z-[5] flex gap-2", {
  variants: {
    position: {
      top: "top-4 left-1/2 -translate-x-1/2",
      bottom: "bottom-4 left-1/2 -translate-x-1/2",
      left: "left-4 top-1/2 -translate-y-1/2",
      right: "right-4 top-1/2 -translate-y-1/2",
      "left-top": "top-4 left-4",
      "top-left": "top-4 left-4",
      "top-right": "top-4 right-4",
      "right-top": "top-4 right-4",
      "bottom-left": "bottom-4 left-4",
      "left-bottom": "bottom-4 left-4",
      "bottom-right": "bottom-4 right-4",
      "right-bottom": "bottom-4 right-4",
    },
    orientation: {
      horizontal: "flex-row",
      vertical: "flex-col",
    },
  },
});

export const ToolbarContext = createContext<ToolbarContextValue>({} as ToolbarContextValue);

export interface ToolbarProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof toolbarVariants> {
  asChild?: boolean;
  orientation?: ToolbarContextValue["orientation"];
  popoverSide?: ToolbarContextValue["popoverSide"];
}

export const Toolbar: FC<ToolbarProps> = ({ position, asChild, className, ...props }) => {
  const Comp = asChild ? Slot : "div";
  let orientation: ToolbarContextValue["orientation"];
  let popoverSide: ToolbarContextValue["popoverSide"];

  const side: ToolbarPopoverSide = position?.split("-")[0] as ToolbarPopoverSide;

  if (position?.startsWith("top") || position?.startsWith("bottom")) {
    orientation = "horizontal";
  } else if (position?.startsWith("left") || position?.startsWith("right")) {
    orientation = "vertical";
  }

  switch (side) {
    case "top":
      popoverSide = "bottom";
      break;
    case "right":
      popoverSide = "left";
      break;
    case "bottom":
      popoverSide = "top";
      break;
    case "left":
      popoverSide = "right";
      break;
  }

  return (
    <ToolbarContext.Provider
      {...props}
      value={{
        position: position!,
        orientation: props.orientation || orientation,
        popoverSide: props.popoverSide || popoverSide,
      }}
    >
      <Comp
        className={cn(toolbarVariants({ position, orientation: props.orientation || orientation, className }), {
          absolute: !!position,
        })}
        {...props}
      />
    </ToolbarContext.Provider>
  );
};

const useToolbar = () => {
  const context = useContext(ToolbarContext);

  if (!context) {
    throw new Error("useToolbar must be used within a ToolbarProvider");
  }

  return context;
};

export interface ToolbarMenuProps extends HTMLAttributes<HTMLDivElement> {
  orientation?: "horizontal" | "vertical";
}

export const ToolbarMenu: FC<ToolbarMenuProps> = ({ className, ...props }) => {
  const { orientation } = useToolbar();

  return (
    <div
      className={cn(
        "flex items-center rounded bg-background shadow-sm",
        {
          "flex-row h-9 px-0.5": props.orientation === "horizontal" || orientation === "horizontal",
          "flex-col w-9 py-0.5": props.orientation === "vertical" || orientation === "vertical",
        },
        className
      )}
      {...props}
    />
  );
};

export interface ToolbarMenuItemProps extends Omit<HTMLAttributes<HTMLDivElement>, "onClick"> {
  icon: ReactNode;
  label?: ReactNode;
  buttonText?: ReactNode;
  shortcut?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonProps?: ComponentProps<typeof Button>;
  active?: boolean;
  disabled?: boolean;
  tooltipOpen?: boolean;
}

export const ToolbarMenuItem = forwardRef<HTMLButtonElement, ToolbarMenuItemProps>(
  (
    { className, icon, label, buttonText, shortcut, onClick, buttonProps, disabled, active, tooltipOpen, ...props },
    ref
  ) => {
    const id = useId();
    const { popoverSide } = useToolbar();

    return (
      <div className="z-10" {...props}>
        <Tooltip delayDuration={0} open={tooltipOpen}>
          <TooltipTrigger asChild>
            <Button
              ref={ref}
              variant="ghost"
              size={buttonText ? "sm" : "icon-lg"}
              aria-labelledby={id}
              onClick={onClick}
              disabled={disabled}
              className={cn({
                "gap-2 pl-2 pr-3": buttonText,
                "text-foreground": active,
              })}
              {...buttonProps}
            >
              <span className="inline-flex text-lg">
                {typeof icon === "string" ? <Icon icon={icon as IconString} /> : icon}
              </span>
              {buttonText && <span>{buttonText}</span>}
            </Button>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent id={id} className="flex items-center !bg-foreground !text-background" side={popoverSide}>
              {label}
              {shortcut && (
                <span className="inline-flex items-center gap-0.5 ml-2 -mr-1.5">
                  {[...shortcut].map((k, i) => (
                    <kbd
                      key={i}
                      className="inline-block text-xs text-muted border border-muted-foreground px-1 py-0.5 rounded-sm leading-none"
                    >
                      {k}
                    </kbd>
                  ))}
                </span>
              )}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    );
  }
);
ToolbarMenuItem.displayName = "ToolbarMenuItem";

export interface ToolbarSeparatorProps extends HTMLAttributes<HTMLDivElement> {}

export const ToolbarSeparator: FC<ToolbarSeparatorProps> = ({ className, ...props }) => {
  const { orientation } = useToolbar();

  const separatorOrientation = orientation === "horizontal" ? "vertical" : "horizontal";

  return (
    <div
      className={cn(
        "flex items-center",
        {
          "h-full mx-0.5": separatorOrientation === "vertical",
          "w-full my-0.5": separatorOrientation === "horizontal",
        },
        className
      )}
      {...props}
    >
      <Separator orientation={separatorOrientation} />
    </div>
  );
};
