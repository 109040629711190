import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionFragment, SubmissionStateRuledOutActions } from "src/generated/graphql";
import { useSubmissionActions } from "./use-submission-actions";

export const RuledOut = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleTransition } = useSubmissionActions();
  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon-sm"
            onClick={() => handleTransition(submission, SubmissionStateRuledOutActions.Revert)}
          >
            <Icon icon="undo" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Revert</TooltipContent>
      </Tooltip>
    </>
  );
};
