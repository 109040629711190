import { UUID } from "@cp/toolkit";
import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { AgentMessage } from "@/components/agent-message";
import { HasInternalRole } from "@/components/has-role";
import { Notes } from "@/components/notes";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { usePolicyQuery } from "src/generated/graphql";
import { PolicyActions } from "./actions/policy-actions";
import { PolicyFiles } from "./policy-files";
import { PolicyInfo } from "./policy-info";

export const Policy = () => {
  const { policyId } = useParams<"policyId">();

  const { data: { policy } = {}, refetch } = usePolicyQuery({
    variables: {
      id: policyId ?? policyId!,
    },
    skip: !policyId,
    fetchPolicy: "cache-and-network",
  });

  if (!policy) {
    return null;
  }

  return (
    <>
      <PolicyInfo policy={policy} />
      <Separator />
      <AgentMessage state={policy.state} wrapperClassName="mx-6 mt-6" />
      <ScrollPaneColumns className="flex-col-reverse grid-cols-[1fr_24em]">
        <Activity opportunityId={policy.opportunityId} />
        <div className="space-y-6">
          <HasInternalRole>
            <Notes opportunityId={policy.opportunityId as UUID} />
          </HasInternalRole>
          <Card>
            <PolicyFiles policy={policy} />
            <HasInternalRole>
              <PolicyActions policy={policy} refetch={refetch} />
            </HasInternalRole>
          </Card>
        </div>
      </ScrollPaneColumns>
    </>
  );
};
