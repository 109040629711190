import { Node, useReactFlow } from "@xyflow/react";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useLocalStorage } from "usehooks-ts";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from "@/components/ui/command";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";
import { useFileProcessingPipelineQuery } from "src/generated/graphql";

import {
  convertPipelineDataToNodesAndEdges,
  findNodeAncestors,
  findParentNodeByChildId,
  getDetailUrlForNode,
  getNodeIcon,
} from "../../../file-processing-pipeline.helpers";
import { useFileProcessingPipelineState } from "../../../file-processing-pipeline.provider";

export const Search = () => {
  const { setExpandedNodes } = useFileProcessingPipelineState();
  const [recentResults, setRecentResults] = useLocalStorage<string[]>("fileProcessingPipelineRecentSearchResults", []);
  const { fitView } = useReactFlow();
  const navigate = useNavigate();
  const { data, loading } = useFileProcessingPipelineQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<string>("");

  const hasValue = value.length > 0;

  const { nodes, edges } = useMemo(() => {
    return data?.fileProcessingPipeline
      ? convertPipelineDataToNodesAndEdges(data?.fileProcessingPipeline)
      : { nodes: [], edges: [] };
  }, [data]);

  const onValueChange = (value: string) => {
    setValue(value);
  };

  useKeyboardShortcut("/", () => setIsOpen((open) => !open), { disabled: isOpen });

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setValue(""), 150);
    }
  }, [isOpen]);

  const handleSelect = (node: Node) => {
    setIsOpen(false);
    navigate(getDetailUrlForNode(node));

    const nodeAncestors = findNodeAncestors(node.id, nodes, edges);

    setExpandedNodes((expandedNodes) => [
      ...nodeAncestors.map((node) => node.id).filter((id) => !expandedNodes.includes(id)),
      ...expandedNodes,
    ]);

    setTimeout(async () => {
      setRecentResults((recentResults) => [node.id, ...recentResults.filter((id) => id !== node.id)].slice(0, 5));

      await fitView({
        nodes: [{ id: node.id }],
        minZoom: 0.75,
        maxZoom: 0.75,
        duration: 1000,
      });
    }, 600);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <ToolbarMenuItem label="Search" shortcut="/" icon="search" tooltipOpen={isOpen ? false : undefined} />
      </PopoverTrigger>
      <PopoverContent align="end" side="bottom" className="p-0">
        <Command loop>
          <CommandInput placeholder="Search..." value={value} onValueChange={onValueChange} className="border-none" />

          <Separator />

          {loading && <CommandLoading className="border-t-border">Loading results&hellip;</CommandLoading>}

          {!loading && (recentResults.length > 0 || hasValue) && <CommandEmpty>No results found</CommandEmpty>}

          <CommandList>
            {!loading && recentResults.length > 0 && (
              <CommandGroup className="max-h-none" heading="Recent">
                {recentResults.map((id) => {
                  const node = nodes.find((node) => node.id === id);

                  if (!node) {
                    return null;
                  }

                  const parentNode = findParentNodeByChildId(node.id, nodes, edges);

                  const label = startCase(toLower((node?.data?.name as string).replaceAll("_", " ")));

                  const parentNodeLabel = parentNode
                    ? startCase(toLower((parentNode?.data?.name as string).replaceAll("_", " ")))
                    : null;

                  return (
                    <CommandItem
                      onSelect={() => handleSelect(node)}
                      key={node.id}
                      value={`${node.id} | ${label}`}
                      className="items-start"
                    >
                      <span className="mt-0.5">
                        <Icon icon={getNodeIcon(node)} />
                      </span>
                      <div className="flex flex-col">
                        <span>{label}</span>
                        <span className="flex gap-0.5 text-xs text-muted-foreground">
                          {parentNodeLabel ? (
                            <>
                              <Icon icon="subdirectory_arrow_left" className="rotate-90" />
                              {parentNodeLabel}
                            </>
                          ) : (
                            <>
                              <Icon icon="line_start_circle" />
                              <em>Root</em>
                            </>
                          )}
                        </span>
                      </div>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {!loading && (
              <CommandGroup className="max-h-none" heading={hasValue ? "Results" : "All nodes"}>
                {nodes.map((node) => {
                  if (recentResults.includes(node.id)) {
                    return null;
                  }

                  const parentNode = findParentNodeByChildId(node.id, nodes, edges);

                  const label = startCase(toLower((node?.data?.name as string).replaceAll("_", " ")));
                  const parentNodeLabel = parentNode
                    ? startCase(toLower((parentNode?.data?.name as string).replaceAll("_", " ")))
                    : null;

                  return (
                    <CommandItem
                      onSelect={() => handleSelect(node)}
                      key={node.id}
                      value={`${node.id} | ${label}`}
                      className="items-start"
                    >
                      <span className="mt-0.5">
                        <Icon icon={getNodeIcon(node)} />
                      </span>
                      <div className="flex flex-col">
                        <span>{label}</span>
                        <span className="flex gap-0.5 text-xs text-muted-foreground">
                          {parentNodeLabel ? (
                            <>
                              <Icon icon="subdirectory_arrow_left" className="rotate-90" />
                              {parentNodeLabel}
                            </>
                          ) : (
                            <em>Root</em>
                          )}
                        </span>
                      </div>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
