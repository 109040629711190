import { useState } from "react";
import { DefaultValues } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";
import { input, z, ZodObject } from "zod";

import { SectionContent, SectionHeader, SectionTitle } from "@/components/section";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Badge } from "@/components/ui/badge";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { Field, Input, TextArea } from "@/forms/default";
import { Reform } from "@/forms/reform";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  CreateVerticalInput,
  UpdateVerticalInput,
  useCreateVerticalMutation,
  useSearchBusinessClassesLazyQuery,
  useUpdateVerticalMutation,
  useVerticalsQuery,
  VerticalFragment,
} from "src/generated/graphql";
import { parseError } from "src/utils";

const CreateSchema = z.object({
  name: z.string(),
  description: z.string(),
});

const UpdateSchema = CreateSchema.extend({
  id: z.string(),
});

export function EditVertical() {
  useDocumentTitle("Admin: Vertical");
  const { verticalId } = useParams<{ verticalId: string | undefined }>();
  const { data: { verticals = [] } = {}, loading } = useVerticalsQuery();

  let vertical: undefined | VerticalFragment;

  if (verticalId) {
    if (loading) {
      return <Loading />;
    }
    vertical = verticals.find((v) => v.id === verticalId);
  }

  return <CreateOrUpdateWrapper vertical={vertical} />; /* Renders update modal */
}

const CreateOrUpdateWrapper: React.FC<{
  vertical?: VerticalFragment;
}> = ({ vertical }) => {
  const [create] = useCreateVerticalMutation({
    onError: (error) =>
      toast({ title: "Error creating vertical", description: parseError(error), variant: "destructive" }),
  });
  const [update] = useUpdateVerticalMutation({
    onError: (error) =>
      toast({ title: "Error updating vertical", description: parseError(error), variant: "destructive" }),
  });

  return vertical ? (
    <VerticalForm
      vertical={vertical}
      schema={UpdateSchema}
      onSubmit={(values, selectedCgls) => {
        void update({
          variables: {
            input: sanitizeUpdateInput({ ...values }, selectedCgls),
          },
          refetchQueries: ["Verticals"],
        });
      }}
      defaultValues={{
        id: vertical.id,
        name: vertical.name,
        description: vertical.description ?? "",
      }}
    />
  ) : (
    <VerticalForm
      schema={CreateSchema}
      onSubmit={(values, selectedCgl) => {
        void create({
          variables: { input: sanitizeCreateInput(values, selectedCgl) },
          refetchQueries: ["Verticals"],
        });
      }}
    />
  );
};

interface VerticalFormProps<T extends ZodObject<any>> {
  vertical?: VerticalFragment;
  schema: T;
  onSubmit: (values: z.infer<T>, selectedCgls: Set<string>) => void;
  defaultValues?: DefaultValues<input<T>>;
}

export const VerticalForm = <T extends ZodObject<any>>({
  vertical,
  schema,
  onSubmit,
  defaultValues,
}: VerticalFormProps<T>) => {
  const [selectedCgls, setSelectedCgls] = useState<Set<string>>(new Set(vertical?.isoCglCodes.map((c) => c.classCode)));

  const navigate = useNavigate();
  return (
    <Reform
      schema={schema}
      onSubmit={(_, values) => {
        onSubmit(values, selectedCgls);
        navigate(-1);
      }}
      defaultValues={defaultValues}
      className="flex flex-auto flex-col"
    >
      <SectionHeader>
        <SectionTitle>
          <h1>{vertical ? "Edit Vertical" : "Create Vertical"}</h1>
        </SectionTitle>
      </SectionHeader>
      <SectionContent className="flex-auto space-y-6">
        <Field label="Name" name="name">
          <Input name="name" />
        </Field>
        <Field
          label={
            <div className="flex flex-row justify-between">
              <span>Description</span>
              <span className="text-sm text-muted-foreground">Optional</span>
            </div>
          }
          name="description"
        >
          <TextArea name="description" />
        </Field>
        <Pills
          items={[...selectedCgls]}
          onDelete={(code) => {
            selectedCgls.delete(code);
            setSelectedCgls(new Set(selectedCgls));
          }}
        />
        <CglSelector
          onSelect={(option) => {
            selectedCgls.has(option.code) ? selectedCgls.delete(option.code) : selectedCgls.add(option.code);
            setSelectedCgls(new Set(selectedCgls));
          }}
        />
      </SectionContent>
      <Bar as="footer">
        <Button type="submit">Save</Button>
      </Bar>
    </Reform>
  );
};

export const CglSelector: React.FC<{ onSelect: (option: BusinessClassFragment) => void }> = ({ onSelect }) => {
  const [load] = useSearchBusinessClassesLazyQuery();

  return (
    <Autocomplete
      options={(text) => {
        return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.IsoGl] } } }).then((res) => [
          ...(res.data?.searchBusinessClasses ?? []),
        ]);
      }}
      onSelect={onSelect}
      toValue={(option) => businessClassToLabel(option)}
      toLabel={(option) => businessClassToLabel(option)}
      placeholder="Search CGL Classifications"
    />
  );
};

export const Pill: React.FC<{
  text: string;
  onClick?: () => void;
  onDelete?: (item: string) => void;
}> = ({ text, onClick, onDelete }) => {
  return (
    <Badge variant="outline" className="bg-slate-300" onClick={onClick}>
      <div className="flex flex-row justify-between gap-1">
        {text}
        {onDelete && (
          <div
            className="flex"
            onClick={(e) => {
              e.preventDefault();
              onDelete(text);
            }}
          >
            <Icon icon="close" />
          </div>
        )}
      </div>
    </Badge>
  );
};

export const Pills: React.FC<{
  items: string[];
  onClick?: () => void;
  onDelete?: (item: string) => void;
}> = ({ items, onClick, onDelete }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <Pill key={item} text={item} onClick={onClick} onDelete={onDelete} />
      ))}
    </div>
  );
};

const sanitizeCreateInput = (
  formValues: z.infer<typeof CreateSchema>,
  selectedCgls: Set<string>
): CreateVerticalInput => {
  const { name, description } = formValues;

  return {
    name: name.trim(),
    description: description?.length === 0 ? undefined : description.trim(),
    isoCglCodes: [...selectedCgls],
  };
};

const sanitizeUpdateInput = (
  formValues: z.infer<typeof UpdateSchema>,
  selectedCgls: Set<string>
): UpdateVerticalInput => {
  return {
    id: formValues.id,
    ...sanitizeCreateInput(formValues, selectedCgls),
  };
};

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;
