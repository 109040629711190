import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { SidebarFooter } from "@/components/sidebar";
import { useInsured } from "@/hooks/use-insured";

export const SidebarAgency = () => {
  const { insured } = useInsured();

  if (!insured?.agent) {
    return null;
  }

  const {
    agent: { firstName, lastName, agency },
  } = insured;

  return (
    <SidebarFooter>
      <div className="space-y-1">
        <div className="font-semibold text-xs truncate">
          {firstName} {lastName}
        </div>
        <div className="text-2xs truncate">{agency.name}</div>
      </div>
      <HasInternalRole>
        <AgencyAffiliations id={agency.id} />
      </HasInternalRole>
    </SidebarFooter>
  );
};
