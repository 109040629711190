import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { FC, useState } from "react";

import { HasInternalRole } from "@/components/has-role";
import { SidePanelList } from "@/components/side-panel";
import { Button } from "@/components/ui/button";
import { DialogHeader } from "@/components/ui/dialog";
import { FileMenu, ViewPDF } from "@/files/file-menu";
import { FileUploadFragment } from "src/generated/graphql";
import { cn } from "src/utils";

export interface FileUpload extends FileUploadFragment {
  labels: string[];
  insuredId?: string | null;
  createdAt: string;
  deletedAt: string;
}

export interface DetailsFilesListProps {
  files: FileUpload[];
  className?: string;
}

export const DetailsFilesList: FC<DetailsFilesListProps> = ({ files, className }) => {
  const [selectedFile, setSelectedFile] = useState<FileUpload | undefined>(undefined);

  return (
    <>
      <SidePanelList className={cn("-mx-2 -mt-0.5 -mb-1 gap-1", className)}>
        {files?.map?.((file) => {
          const date = file.deletedAt || file.createdAt;

          return (
            <li key={file.id} className="flex gap-2 items-center text-xs">
              <div className="max-w-[70%] basis-[70%]">
                <Button
                  size="sm"
                  variant="ghost"
                  title={file.filename}
                  onClick={() => setSelectedFile(file)}
                  className="inline-block w-auto max-w-full text-left px-2 text-foreground truncate"
                >
                  {file.filename}
                </Button>
              </div>
              <div className="flex-1" />
              <div className="w-[3.5rem] text-right text-muted-foreground">
                {!!date && format(new Date(date), "M/d/yy")}
              </div>
              <HasInternalRole>
                <div className="w-[1.5rem] text-right">
                  <FileMenu file={file} include={["viewInsured", "download"]} />
                </div>
              </HasInternalRole>
            </li>
          );
        })}
      </SidePanelList>

      {selectedFile && (
        <Dialog open={!!selectedFile} onOpenChange={() => setSelectedFile(undefined)}>
          <DialogHeader className="hidden">
            <DialogTitle>Files</DialogTitle>
          </DialogHeader>
          <ViewPDF file={selectedFile} files={files || []} setSelectedFile={setSelectedFile} />
        </Dialog>
      )}
    </>
  );
};
