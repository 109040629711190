import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { FormEvent } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { FormField, FormItem } from "@/components/ui/form";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Field, FieldInput, fieldInputClassName } from "@/forms/borderless";
import { BorderlessCurrency } from "@/forms/borderless-currency";
import { useMyAccount } from "@/hooks/use-my-account";
import {
  useAgenciesQuery,
  useAgentAccountsQuery,
  useAppetiteProductLinesQuery,
  useAppetiteProductsCountQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { AgentForm } from "./agent-form";
import { AgentFormSchema } from "./use-create-opportunity";

interface InsuredFormProps {
  formMethods: UseFormReturn<z.infer<typeof AgentFormSchema>>;
  uploadInProgress: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const InsuredForm: React.FC<InsuredFormProps> = ({ formMethods, uploadInProgress, onSubmit }) => {
  const { data: user } = useMyAccount();

  const { data: linesData, loading: loadingLines } = useAppetiteProductLinesQuery();
  const { data: productsCount, loading: loadingProducts } = useAppetiteProductsCountQuery();

  const { data: { userAccounts: agents = [] } = {} } = useAgentAccountsQuery({ skip: !user?.internal });
  const { data: { agencies = [] } = {} } = useAgenciesQuery({ skip: !user?.internal });

  return (
    <FormProvider {...formMethods}>
      <form id="appetiteForm" onSubmit={(e) => onSubmit(e)}>
        <div className="bg-border border-b space-y-px">
          <div className="bg-background flex flex-row gap-5 items-start p-6 pl-5">
            <Icon icon="psychology_alt" className="filled mt-0.5 text-2xl text-primary" />
            <div className="space-y-3">
              <h2>Insured Basics</h2>
              <p className="text-base">
                Provide a few details about your insured to help us start quoting from our panel of{" "}
                <strong>
                  {loadingProducts ? <Spinner className="inline text-xs" /> : productsCount?.productsCount} risk
                  solutions
                </strong>{" "}
                offering{" "}
                <strong>
                  {loadingLines ? <Spinner className="inline text-xs" /> : linesData?.appetiteProductLines.length} types
                  of coverage
                </strong>
                .
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-px">
            <FieldInput
              icon="business_center"
              placeholder={uploadInProgress ? "Loading..." : "Insured Name (This is how it will appear on the Policy)"}
              disabled={uploadInProgress}
              required={true}
              {...formMethods.register("clientName")}
            />
            <Field icon="location_on">
              <FormField
                control={formMethods.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="flex-auto">
                    <Select key={field.value} onValueChange={field.onChange} value={field.value} required>
                      <SelectTrigger className={fieldInputClassName} disabled={uploadInProgress}>
                        <SelectValue placeholder={uploadInProgress ? "Loading..." : "Primary State"} />
                      </SelectTrigger>
                      <SelectContent>
                        {STRICT_STATE_OPTIONS.map((state) => (
                          <SelectItem key={state.value} value={state.value}>
                            {state.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </Field>
            <BorderlessCurrency
              autoFocus
              icon="attach_money"
              control={formMethods.control}
              name="revenue"
              required={false}
              placeholder="Gross Revenue"
            />
            <Field icon="storefront" className="items-start py-2" iconClassName="mt-2.5">
              <Textarea
                placeholder={uploadInProgress ? "Loading..." : "Business Description"}
                disabled={uploadInProgress}
                className={cn("resize-none", fieldInputClassName)}
                required={true}
                {...formMethods.register("description")}
              />
            </Field>
          </div>
        </div>
        <AgentForm
          agents={agents}
          agencies={agencies}
          register={formMethods.register}
          setValues={(valPairs) => {
            valPairs.forEach(([k, v]) => formMethods.setValue(k as keyof z.infer<typeof AgentFormSchema>, v));
            // trigger to update formMethods.formState.isValid, which enables/disables the submit button
            void formMethods.trigger();
          }}
        />
      </form>
    </FormProvider>
  );
};
