import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionFragment, SubmissionStatePendingActions } from "src/generated/graphql";
import { EmailDraft } from "./email-draft";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const Pending = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleConfirmation, handleTransition } = useSubmissionActions();
  return (
    <>
      <EmailDraft submission={submission} />
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon-sm"
            onClick={() => handleTransition(submission, SubmissionStatePendingActions.Submit)}
          >
            <Icon icon="check_circle" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Mark Submitted</TooltipContent>
      </Tooltip>
      <SubmissionMenu submission={submission}>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionStatePendingActions.Submit)}>
          <Icon icon="check_circle" />
          Mark Submitted
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleConfirmation(submission, SubmissionStatePendingActions.RuleOut, true)}>
          <Icon icon="block" />
          Rule Out
        </DropdownMenuItem>
      </SubmissionMenu>
    </>
  );
};
