import { format } from "date-fns";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

import { SidePanelDetailsHeader, SidePanelList } from "@/components/side-panel";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Icon } from "@/components/ui/icon";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from "@/components/ui/tooltip";
import { FileProcessorVersionState } from "src/generated/graphql";
import { cn } from "src/utils";

export interface FileProcessorVersion {
  id: string;
  displayName: string;
  state: FileProcessorVersionState;
  createdAt?: Date;
  f1Score?: number | null;
}

export interface DetailsProcessorVersionsListProps {
  processorId: string;
  processorVersions?: FileProcessorVersion[] | null;
  defaultProcessorVersionId?: string | null;
}

export interface DetailsProcessorVersionsListItemProps {
  processorVersion: FileProcessorVersion & {
    isDefault: boolean;
    processorId: string;
  };
}

export const DetailsProcessorVersionsList: FC<DetailsProcessorVersionsListProps> = ({
  processorId,
  processorVersions,
  defaultProcessorVersionId,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!processorId || !processorVersions?.length || !defaultProcessorVersionId) {
    return null;
  }

  const defaultVersion = processorVersions?.find(
    (processorVersion) => processorVersion.id === defaultProcessorVersionId
  );

  const versionsUrl = `https://console.cloud.google.com/ai/document-ai/locations/us/processors/${processorId}/v2/deploy-and-use/versions`;
  const versionsCount = processorVersions.length;

  return (
    <Collapsible open={isExpanded} onOpenChange={setIsExpanded} asChild>
      <div className="flex flex-col gap-2">
        <SidePanelDetailsHeader label="Processor versions">
          {versionsCount > 1 && (
            <>
              <Badge variant="outline" className="-my-2 px-1.5">
                {versionsCount >= 20 ? "20+" : versionsCount}
              </Badge>

              <span className="flex-1" />

              <div className="flex items-center -my-2 -mr-1.5">
                {!isExpanded && versionsCount >= 20 && (
                  <Button variant="ghost" size="xs" asChild>
                    <Link to={versionsUrl} target="_blank">
                      View all
                    </Link>
                  </Button>
                )}

                <CollapsibleTrigger asChild>
                  <Button variant="ghost" size="icon-sm">
                    <Icon icon={isExpanded ? "unfold_less" : "unfold_more"} />
                  </Button>
                </CollapsibleTrigger>
              </div>
            </>
          )}
        </SidePanelDetailsHeader>

        <div className="-mx-2 -mt-0.5 -mb-1">
          {!isExpanded && (
            <DetailsProcessorVersionsListItem
              processorVersion={{
                ...(defaultVersion || processorVersions[0]),
                processorId,
                isDefault: !!defaultVersion,
              }}
            />
          )}

          {!isExpanded && !defaultVersion && (
            <div className="mt-2.5 px-2">
              <Alert className="pb-3.5">
                <div className="flex gap-2">
                  <div>
                    <Icon icon="error" className="text-base" />
                  </div>
                  <div>
                    <AlertTitle>Default processor version not set</AlertTitle>
                    <AlertDescription>Showing most recent version instead.</AlertDescription>
                  </div>
                </div>
              </Alert>
            </div>
          )}

          <CollapsibleContent>
            <SidePanelList className="gap-0">
              {processorVersions.map?.((processorVersion) => (
                <DetailsProcessorVersionsListItem
                  key={processorVersion.id}
                  processorVersion={{
                    ...processorVersion,
                    processorId,
                    isDefault: processorVersion.id === defaultProcessorVersionId,
                  }}
                />
              ))}

              {versionsCount >= 20 && (
                <Button variant="ghost" size="sm" className="-mb-2" asChild>
                  <Link to={versionsUrl} target="_blank">
                    View all versions
                  </Link>
                </Button>
              )}
            </SidePanelList>
          </CollapsibleContent>
        </div>
      </div>
    </Collapsible>
  );
};

export const DetailsProcessorVersionsListItem: FC<DetailsProcessorVersionsListItemProps> = ({ processorVersion }) => {
  const versionUrl = `https://console.cloud.google.com/ai/document-ai/locations/us/processors/${processorVersion.processorId}/v2/evaluate;processorVersionId=${processorVersion.id}`;

  return (
    <HoverCard openDelay={300}>
      <Button
        variant="ghost"
        size="sm"
        key={processorVersion.id}
        className="flex flex-row items-center gap-2 px-2 h-9 text-muted-foreground"
        asChild
      >
        <Link to={versionUrl} target="_blank" rel="noreferrer">
          <HoverCardTrigger asChild>
            <h3
              className={cn("text-xs text-foreground font-semibold leading-tight tracking-tight max-w-[65%] truncate", {
                "max-w-[42%]": processorVersion.isDefault,
              })}
            >
              {processorVersion.displayName}
            </h3>
          </HoverCardTrigger>

          {processorVersion.isDefault && <Badge variant="secondary">default</Badge>}

          <div className="flex-1" />

          {processorVersion.createdAt && <span>{format(new Date(processorVersion.createdAt), "M/d/yy")}</span>}

          <div className="text-xs flex items-center gap-1 text-muted-foreground">
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <div>
                  <Progress value={Number(processorVersion.f1Score) * 100} className="h-2 w-8" />
                </div>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  <span className="text-muted-foreground">F1 score:</span>{" "}
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 3,
                  }).format(Number(processorVersion.f1Score))}{" "}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </div>

          <div className="leading-none">
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <span>
                  <Icon
                    icon="circle"
                    className={cn("mt-[2px] filled text-amber-500 text-xs", {
                      "text-green-500": processorVersion.state === FileProcessorVersionState.Deployed,
                      "text-destructive": processorVersion.state === FileProcessorVersionState.Failed,
                    })}
                  />
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>{processorVersion.state?.toLocaleLowerCase()}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </div>
        </Link>
      </Button>

      <HoverCardContent>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between gap-2">
            <h3
              className={cn("text-sm font-semibold leading-tight tracking-tight", {
                "max-w-[72%]": processorVersion.isDefault,
              })}
            >
              <Link to={versionUrl} target="_blank" rel="noreferrer" className="break-words text-wrap">
                {processorVersion.displayName}
              </Link>
            </h3>

            {processorVersion.isDefault && <Badge variant="secondary">default</Badge>}
          </div>

          <Separator />

          <div className="flex flex-col gap-3 text-xs">
            <div className="flex items-center justify-between gap-1">
              <span className="text-muted-foreground">State</span>
              <div>
                <Badge variant="outline" className="grow-0 inline-flex gap-1 pr-1 pl-2">
                  <span>{processorVersion.state?.toLocaleLowerCase()}</span>
                  <Icon
                    icon="circle"
                    className={cn("filled text-amber-500 text-xs", {
                      "text-green-500": processorVersion.state === FileProcessorVersionState.Deployed,
                      "text-destructive": processorVersion.state === FileProcessorVersionState.Failed,
                    })}
                  />
                </Badge>
              </div>
            </div>

            <div className="flex items-center justify-between gap-1">
              <span className="text-muted-foreground">F1 score</span>
              <div className="flex items-center gap-2">
                <div>
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 3,
                  }).format(Number(processorVersion.f1Score))}
                </div>
              </div>
            </div>

            {processorVersion.createdAt && (
              <div className="flex items-center justify-between gap-1">
                <span className="text-muted-foreground">Created date</span>
                <span>{format(new Date(processorVersion.createdAt), "M/d/yy")}</span>
              </div>
            )}
          </div>

          <Separator />

          <div className="flex flex-col">
            <span className="text-xs text-muted-foreground">Version ID</span>
            <span>
              <Button variant="link" size="sm" asChild className="p-0 h-auto break-words text-wrap">
                <Link to={versionUrl} target="_blank" rel="noreferrer">
                  {processorVersion.id}
                </Link>
              </Button>
            </span>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
