import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { domain } from "@cp/toolkit";
import {
  useDeactivateUserAccountMutation,
  UserAccountFragment,
  useUpdateUserAccountMutation,
} from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { BankanCard, BankanLinkFooter, BankanLinkText } from "./bankan-cards";

export const UserCard = ({ user }: { user: UserAccountFragment }) => {
  const { firstName, lastName, email, createdAt } = user;

  return (
    <BankanCard>
      <BankanLinkText className="font-semibold">{`${firstName} ${lastName} (${email})`}</BankanLinkText>
      <BankanLinkFooter>
        Created {formatTimezoneDateString(createdAt, "MMM d, yyyy")}
        <div className="flex gap-1">
          <Approve user={user} />
          <Deny user={user} />
        </div>
      </BankanLinkFooter>
    </BankanCard>
  );
};

const Approve = ({ user }: { user: UserAccountFragment }) => {
  const { id, firstName, lastName, agency, email } = user;
  const [updateUserAccount] = useUpdateUserAccountMutation();
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon-xs">
          <Icon icon="check" className="text-success font-bold" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
        </DialogHeader>
        <span>
          {firstName} {lastName} could be impersonating as an agent from {agency.name}!
          <br />
          <br />
          {firstName} was flagged because their email domain <strong>&apos;{domain(email)}&apos;</strong> is not one of
          the known email domains for {agency.name}.
          <br />
          <br />
          Please confirm {firstName} is a legitimate agent from {agency.name}, and that their email domain is correct.
          <br />
          <br />
          Approving will (1) grant them access to all of {agency.name}&apos;s data, and (2) will make
          <strong>&apos;{domain(email)}&apos;</strong> a known email domain for {agency.name}, which means that any
          future users with a <strong>&apos;{domain(email)}&apos;</strong> email will be automatically verified for this
          Agency.
          <i>(Note: generic email domains like gmail.com, outlook.com, etc. will never be added as known domains).</i>
        </span>
        <Button
          onClick={() => {
            void updateUserAccount({
              variables: { id, input: { verifyEmailDomain: true } },
              refetchQueries: ["UserAccounts"],
            });
            setOpen(false);
          }}
        >
          Approve
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const Deny = ({ user }: { user: UserAccountFragment }) => {
  const { id } = user;
  const [deactivateUser] = useDeactivateUserAccountMutation();
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon-xs">
          <Icon icon="delete" className="text-red-700" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
        </DialogHeader>
        <Button
          variant="destructive"
          onClick={() => {
            void deactivateUser({
              variables: { id },
              refetchQueries: ["UserAccounts"],
            });
            setOpen(false);
          }}
        >
          Deny User
        </Button>
      </DialogContent>
    </Dialog>
  );
};
