import { ContainerHeaderCell } from "@/components/container";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { useInsured } from "@/hooks/use-insured";
import { Classification } from "./classification";
import { SelectAgent } from "./select-agent";
import { Summary } from "./summary";

export const InsuredDetails = () => {
  const { insured } = useInsured();

  if (!insured) {
    return null;
  }

  const { agent } = insured;

  return (
    <>
      <ScrollPaneColumns className="grid-cols-2">
        <Summary insured={insured} />
        <div className="space-y-6">
          <Classification insured={insured} />
          <SelectAgent insured={insured}>
            <ContainerHeaderCell title={`${agent.firstName} ${agent.lastName}`} subtitle={agent.agency.name} />
          </SelectAgent>
        </div>
      </ScrollPaneColumns>
    </>
  );
};
