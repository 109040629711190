import { NoActions } from "@/components/no-actions";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PolicyFragment } from "src/generated/graphql";
import { FileButton } from "../components/file-button";

export const PolicyFiles = ({ policy }: { policy: PolicyFragment }) => {
  const { policyFile, redactedBinderFile, processedBinderFile } = policy;
  return (
    <>
      <CardHeader>
        <CardTitle>Files</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {policyFile && <FileButton file={policyFile} />}
        {redactedBinderFile && <FileButton file={redactedBinderFile} badge="Redacted" />}
        {processedBinderFile && <FileButton file={processedBinderFile} badge="Processed" />}
        {!policyFile && !redactedBinderFile && !processedBinderFile && <NoActions label="No Files Found" />}
      </CardContent>
    </>
  );
};
