import { UserState } from "@/column-metadata";
import { Loading } from "@/components/ui/loading";
import { useUserAccountsQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { UserCard } from "./cards/user-card";

export const UserColumn = ({ agencyId }: { agencyId?: string | null }) => {
  const { data: { userAccounts } = { userAccounts: [] }, loading } = useUserAccountsQuery({
    variables: {
      input: {
        agencyId,
        emailDomainVerified: false,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <BankanColumn id={UserState.EmailDomainUnverified} count={userAccounts.length}>
      {loading ? <Loading /> : userAccounts.map((user) => <UserCard key={user.id} user={user} />)}
    </BankanColumn>
  );
};
