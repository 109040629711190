import { useAgencyAffiliationQuery } from "src/generated/graphql";

export const AgencyAffiliations = ({ id }: { id: string }) => {
  const { data: { agency = {} } = {} } = useAgencyAffiliationQuery({
    variables: {
      id,
    },
  });

  if (!agency.affiliations) {
    return null;
  }

  return (
    <>
      {agency.affiliations[0] && (
        <div className="space-y-0.5">
          {agency.affiliations.map((affiliation) => (
            <div key={affiliation.name} className="flex gap-2 items-center text-2xs">
              <div className="flex flex-auto gap-3 items-center truncate">
                {affiliation.logoUrl ? (
                  <img src={affiliation?.logoUrl} className="max-h-8 max-w-24 rounded" />
                ) : (
                  <span className="font-semibold truncate">{affiliation?.name}</span>
                )}
              </div>
              {(affiliation.brokerageCommission || affiliation.bindingCommission) && (
                <div className="flex-none gap grid grid-cols-2 items-center leading-none w-24">
                  {affiliation.brokerageCommission && (
                    <>
                      <span>Brokerage:</span>
                      <span className="text-right">{`${Number(affiliation?.brokerageCommission).toFixed(2)}%`}</span>
                    </>
                  )}
                  {affiliation.bindingCommission && (
                    <>
                      <span>Binding:</span>
                      <span className="text-right">{`${Number(affiliation?.bindingCommission).toFixed(2)}%`} </span>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
