import { Outlet, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { useAgencyQuery } from "src/generated/graphql";

export const LaunchAgency = () => {
  const { agencyId } = useParams();

  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId!,
    },
    skip: !agencyId,
  });

  useDocumentTitle(`Launch: ${agency?.name}`);

  if (!agency) {
    return null;
  }

  const { name, owner } = agency;

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>{name}</h1>
        </SectionTitle>
        <SectionDescription>{owner ? `${owner.firstName} ${owner.lastName}` : "No owner"}</SectionDescription>
      </SectionHeader>
      <Bar>
        <BarLinks>
          <BarLink to="." end>
            Agents &amp; Insureds
          </BarLink>
        </BarLinks>
      </Bar>
      <Outlet />
    </>
  );
};

export const AgencyCrumb = () => {
  const { agencyId } = useParams();

  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId!,
    },
    skip: !agencyId,
  });

  if (!agency) {
    return null;
  }

  return <Link to={`/arm/${agency.id}`}>{agency.name}</Link>;
};
