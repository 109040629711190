import type { FC } from "react";
import { Link } from "react-router-dom";

import { SidePanelList } from "@/components/side-panel";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";

export interface DetailsLinksListProps {
  links: Array<{ url: string; label: string }>;
}

export const DetailsLinksList: FC<DetailsLinksListProps> = ({ links }) => (
  <SidePanelList className="-mx-2 -mt-0.5 -mb-1 gap-0">
    {links.map((link) => (
      <li key={link.label}>
        <Button variant="ghost" className="inline-flex h-8 px-2" asChild>
          <Link to={link.url} target="_blank" title={link.label}>
            <span className="truncate text-foreground font-normal">{link.label}</span>
            <Icon icon="open_in_new" />
          </Link>
        </Button>
      </li>
    ))}
  </SidePanelList>
);
